import React, { useState } from "react";

// 자주 묻는 질문
const faqList = [
  {
    title: "FIVS 서비스 문의",
    desc: `The Value 플랫폼 FIVS 서비스는 아래와 같습니다. \n
1. 메자닌 상품(RCPS, CB, Stock Option 등) 공정가치 평가\n
2. 평가 시 적용한 파라미터 제공(커브(YTM/Spot), 변동성 산출내역; 필요 시 주가/Ve/Vd Tree 데이터)\n
3. 평가보고서 제공\n
4. 민감도 분석내역 제공\n
특이케이스 시 추가작업이 필요하며, 난이도에 따라 추가비용이 발생할 수 있습니다.\n
금융기관(증권사, 은행 등)의 경우 연/월 단위 구독서비스를 제공드리며, 서비스 범위 및 요건에 따라 추가적인 협의가 필요합니다.`,
  },
  {
    title: "웹 개발 문의",
    desc: `VALUES의 웹 개발 문의는 홈페이지 하단의 문의하기 또는 아래 정보로 이메일 주시면 신속하게 답변 드리도록 하겠습니다. \n
이메일 주소 : dev@the-value.co.kr`,
  },
  {
    title: "웹 유지보수 문의",
    desc: `VALUES의 웹 유지보수 문의는 홈페이지 하단의 문의하기 또는 아래 정보로 이메일 주시면 신속하게 답변 드리도록 하겠습니다. \n
    이메일 주소 : dev@the-value.co.kr`,
  },
];

// 자주묻는 질문
const Faq = () => {
  //Faq selected value
  const [selected, setSelected] = useState(null);
  //Faq toggleMethod
  const faqToggleMethod = (index) => {
    // console.log(index);

    // toggle start
    if (selected !== index) {
      setSelected(index);
    } else {
      setSelected(null);
    }
  };

  return (
    <section
      id="faq"
      className="relative z-10 bg-[#F8FAFB] py-[110px] dark:bg-[#15182B]"
    >
      <div className="container">
        <div
          className="wow fadeInUp mx-auto mb-14 max-w-[690px] text-center lg:mb-[70px]"
          data-wow-delay=".2s"
        >
          <h2 className="text-3xl font-bold text-black dark:text-white sm:text-4xl md:text-[44px] md:leading-tight">
            자주 묻는 질문
          </h2>
          {/* <h4 className="my-4">Frequently Asked Questions</h4> */}
          <p className="text-base text-body my-4">
            VALUES 고객님들의 자주 묻는 질문 및 답변입니다.
          </p>
        </div>

        <div
          className="faqs wow fadeInUp mx-auto w-full max-w-[785px] rounded-lg bg-white px-6 py-[6px] shadow-card dark:bg-black dark:shadow-card-dark"
          data-wow-delay=".3s"
        >
          {faqList.map(({ title, desc }, index) => {
            // console.log(index);
            return (
              <div
                key={index}
                className={`faq border-b border-stroke last-of-type:border-none dark:border-stroke-dark ${
                  selected === index ? "active" : ""
                }`}
              >
                <button
                  onClick={() => faqToggleMethod(index)}
                  className="faq-btn relative flex w-full justify-between py-6 px-[18px] text-left text-base font-medium text-black dark:text-white sm:px-[26px] sm:text-lg"
                >
                  {title}
                </button>
                <div className="faq-content h-auto overflow-hidden border-t border-stroke px-[18px] dark:border-stroke-dark sm:px-[26px]">
                  <p className="whitespace-pre-line text-base text-body">
                    {desc}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* <!-- Graphics --> */}
      <div className="absolute right-0 -top-24 -z-10">
        <svg
          width="95"
          height="190"
          viewBox="0 0 95 190"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="95"
            cy="95"
            r="77"
            stroke="url(#paint0_linear_49_603)"
            stroke-width="36"
          />
          <defs>
            <linearGradient
              id="paint0_linear_49_603"
              x1="0"
              y1="0"
              x2="224.623"
              y2="130.324"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FF8FE8" />
              <stop offset="1" stop-color="#FFC960" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="absolute left-0 -bottom-24 -z-10">
        <svg
          width="95"
          height="190"
          viewBox="0 0 95 190"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cy="95"
            r="77"
            stroke="url(#paint0_linear_52_83)"
            stroke-width="36"
          />
          <defs>
            <linearGradient
              id="paint0_linear_52_83"
              x1="-117.84"
              y1="190"
              x2="117.828"
              y2="25.9199"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8EA5FE" />
              <stop offset="0.541667" stop-color="#BEB3FD" />
              <stop offset="1" stop-color="#90D1FF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
  );
};

export default Faq;
