import { Outlet, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./App.css";
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import LandingPage from "./pages/LandingPage";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import TermsOfUse from "./pages/Register/Component/TermsOfUse";
import Search from "./pages/Search";
import AssetModel from "./pages/AssetModel";
import PatentCompleted from "./pages/ValuationAutomation/Component/PatentCompleted";
import ValuationAuto from "./pages/ValuationAutomation/Component/ValuationAuto";
import MrcAuto from "./pages/ValuationAutomation/Component/MrcAuto";
import FivsSolution from "./pages/ItSolution/Component/FivsSolution";
import WebSolution from "./pages/ItSolution/Component/WebSolution";
import PreparingSolution from "./pages/ItSolution/Component/PreparingSolution";
import FinanceSolution from "./pages/ItSolution/Component/FinanceSolution";
import Notification from "./pages/CustomerSupport/Component/Notification";
import Faq from "./pages/LandingPage/Component/Faq";
import FaqSupport from "./pages/CustomerSupport/Component/FaqSupport";
import RcpsSupport from "./pages/CustomerSupport/Component/RcpsSupport";
import WebSupport from "./pages/CustomerSupport/Component/WebSupport";
import WebCareer from "./pages/DevelopmentCareer/Component/WebCareer";
import RcpsCareer from "./pages/DevelopmentCareer/Component/RcpsCareer";
import MrcCareer from "./pages/DevelopmentCareer/Component/MrcCareer";
import MaintenanceCareer from "./pages/DevelopmentCareer/Component/MaintenanceCareer";
import CompanyInformation from "./pages/CompanyIntroduction/Component/CompanyInformation";
import CompanyPartner from "./pages/CompanyIntroduction/Component/CompanyPartner";
import CompanyBlog from "./pages/CompanyIntroduction/Component/CompanyBlog";
import NavigationMenuBar from "./layout/NavigationMenuBar";
import PreparingPage from "./pages/PreparingPage/index";

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    // background-color: #216ba5;
    // color: white;
    border-radius: 7px;
    font-size: 14px;
  }
`;

// navBar List
export const navBarList = [
  {
    idx: 0,
    menuTitle: "VALUATION 자동화",
    menuList: [
      {
        to: "/valuationAuto",
        menuName: "파생상품평가(FIVS) 자동화 시스템",
        desc: "매자닌 상품(CB, RCPS, SO 등) 및 IRS, CRS, 통화선도 등의 파생상품을 평가합니다.",
      },
      {
        to: "/patentCompleted",
        menuName: "FIVS 특허완료",
        desc: "VALUES만의 평가 방법론이 적용된 기술에 대한 특허 등록이 완료되었습니다.",
      },
      {
        to: "/mrcAuto",
        menuName: "내부통제 (MRC)",
        desc: "기초설정부터 전문가 질의서, 평가검증 및 회계처리, 상급자 상신과 MRC 문서생성까지 이 모든 것을 한번에 관리할 수 있습니다.",
      },
    ],
  },
  {
    idx: 1,
    menuTitle: "IT 개발 & 솔루션",
    menuList: [
      {
        to: "/fivsSolution",
        menuName: "FIVS",
        desc: "VALUES의 파생상품평가의 자동화 시스템인 FIVS를 도입하여 더 빠르고, 정확한 결과를 보실 수 있습니다.",
      },
      {
        to: "/webSolution",
        menuName: "웹 개발과 유지보수",
        desc: "고객의 니즈에 맞는 다양한 웹 개발과, 유지보수를 하고 있습니다.",
      },
      {
        to: "/preparingSolution",
        menuName: "준비중인 기능",
        desc: "VALUES는 시대에 맞는 아이템을 개발하고, 끊임없이 새로운 도전을 하고 있습니다.",
      },
      {
        to: "/financeSolution",
        menuName: "금융 컨설팅",
        desc: "파생상품평가 평가 체계에 대한 자문 컨설팅을 하고 있습니다.",
      },
    ],
  },
  {
    idx: 2,
    menuTitle: "고객지원",
    menuList: [
      {
        to: "/notification",
        menuName: "공지사항",
        desc: "VALUES의 신기술 또는 업데이트한 정보를 보실 수 있습니다.",
      },
      {
        to: "/faqSupport",
        menuName: "자주 묻는 질문",
        desc: " 고객의 니즈를 충족시키기 위해, 자주 묻는 질문 리스트에 대한 답변을 보실 수 있습니다.",
      },
      {
        to: "/rcpsSupport",
        menuName: "파생상품평가 관련 문의",
        desc: "채권평가, 파생상품 평가, 자산평가 문의를 신청할 수 있습니다.",
      },
      {
        to: "/webSupport",
        menuName: "웹 개발 및 유지보수 문의",
        desc: "다양한 스타일의 웹 개발과 유지보수를 신청할 수 있습니다.",
      },
    ],
  },
  {
    idx: 3,
    menuTitle: "개발이력",
    menuList: [
      {
        to: "/webCareer",
        menuName: "웹 개발 및 유지보수 이력",
        desc: "VALUES만의 다양한 컴퓨터 언어 기술을 사용한 개발 이력을 보실 수 있습니다.",
      },
      {
        to: "/rcpsCareer",
        menuName: "파생상품평가(FIVS) 이력",
        desc: "파생상품평가의 자동화 시스템의 발전 이력을 보실 수 있습니다.",
      },
      // {
      //   to: "/mrcCareer",
      //   menuName: "MRC 내부통제 이력",
      //   desc: "VALUES의 신기술인 내부통제의 자동화 시스템 이력을 보실 수 있습니다.",
      // },
      // {
      //   to: "/maintenanceCareer",
      //   menuName: "유지보수 이력",
      //   desc: "VALUES 개발팀의 유지보수 이력을 보실 수 있습니다.",
      // },
    ],
  },
  {
    idx: 4,
    menuTitle: "회사소개",
    menuList: [
      {
        to: "/companyInformation",
        menuName: "기업정보",
        desc: "VALUES의 핵심 가치와 나아갈 방향을 소개합니다.",
      },
      {
        to: "/companyPartner",
        menuName: "VALUES 파트너",
        desc: "VALUES의 기술을 사용하고 있는 파트너들을 소개합니다.",
      },
      {
        to: "/companyBlog",
        menuName: "BLOG",
        desc: "VALUES의 기술에 대한 블로그를 보실 수 있습니다.",
      },
    ],
  },
];

//서치용 페이지용 레이아웃
function LayoutSearch({ darkMode }) {
  return (
    <div className="h-full">
      <StyledToastContainer
        position="bottom-left"
        autoClose={1000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
        limit={1}
      />
      <div className={`${darkMode ? "dark" : "light"} h-full`}>
        <div className="bg-white dark:bg-black h-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

//LandingPage용 레이아웃
function LayoutLanding({ themeChange, darkMode }) {
  return (
    <div>
      <StyledToastContainer
        position="bottom-left"
        autoClose={1000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
        limit={1}
      />
      <div className={`${darkMode ? "dark" : "light"}`}>
        <div className="bg-white dark:bg-black">
          <Header themeChange={themeChange} navBarList={navBarList} />
          <Outlet />
          <Footer />
        </div>
      </div>
    </div>
  );
}

// Menu Click시 화면 레이아웃
function LayoutBasic({ themeChange, darkMode }) {
  return (
    <div>
      <StyledToastContainer
        position="bottom-left"
        autoClose={1000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
        limit={1}
      />
      <div className={`${darkMode ? "dark" : "light"}`}>
        <div className="bg-white dark:bg-black">
          <Header themeChange={themeChange} navBarList={navBarList} />
          <NavigationMenuBar navBarList={navBarList} />
          {/* <section className="pt-[55px] overflow-hidden md:my-16"> */}
          {/* <div className="mt-10 md:h-[500px] h-[800px] w-full flex justify-center"> */}
          <section className="pt-[15px] overflow-hidden md:my-16">
            <div className="mt-4 md:h-auto h-auto w-full flex justify-center">
              <div className="dark:text-white md:max-w-[1340px] w-full 2xl:px-2 md:px-6 px-6">
                <Outlet />
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </div>
  );
}

function App() {
  const [darkMode, setDarkMode] = useState(false); // dark mode

  // 처음 페이지 로딩할 때 로컬스토리지에서 테마 찾기
  const themeCheck = () => {
    const theme = localStorage.getItem("theme");
    if (theme === "dark") {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  };

  // 헤더 토글 메소드
  const themeChange = () => {
    const mode = !darkMode;
    setDarkMode(mode);

    if (mode) {
      localStorage.setItem("theme", "dark");
    } else {
      localStorage.setItem("theme", "light");
    }
  };

  useEffect(() => {
    // 페이지 로딩 시 테마 체크 함수 호출
    themeCheck();
  }, []);

  return (
    <>
      {" "}
      {/* dark:bg-black : 부모 div가 dark클래스를 갖고 있으면 bg-black 클래스가 적용됨
      아닐 경우에는 bg-white 클래스가 적용됨 */}
      <Routes>
        {/* Landing Page */}
        <Route
          path="/"
          element={
            <LayoutLanding themeChange={themeChange} darkMode={darkMode} />
          }
        >
          <Route index element={<LandingPage />} />
          {/* <Route path="register/termsofuse" index element={<TermsOfUse />} />
          <Route path="login" index element={<Login />} />
          <Route path="register" index element={<Register />} /> */}
        </Route>

        {/************************** Header 메뉴 START ***************************/}
        <Route
          path="/"
          element={
            <LayoutBasic themeChange={themeChange} darkMode={darkMode} />
          }
        >
          {/* ------------------- VALUATION 자동화 --------------------- */}
          {/* 파생상품평가(FIVS) 자동화 시스템 */}
          <Route path="valuationAuto" index element={<ValuationAuto />} />
          {/* FIVS 특허완료 */}
          <Route path="patentCompleted" index element={<PatentCompleted />} />
          {/* 내부통제 (MRC) */}
          <Route path="mrcAuto" index element={<MrcAuto />} />

          {/* --------------------- IT 컨설팅 & 솔루션 ------------------- */}
          {/* FIVS  */}
          <Route path="fivsSolution" index element={<FivsSolution />} />
          {/* 웹 솔루션과 유지보수  */}
          <Route path="webSolution" index element={<WebSolution />} />
          {/* 준비중인 기능  */}
          <Route
            path="preparingSolution"
            index
            // element={<PreparingSolution />}
            element={<PreparingPage />}
          />
          {/* 금융 컨설팅  */}
          {/* <Route path="financeSolution" index element={<FinanceSolution />} /> */}
          <Route path="financeSolution" index element={<PreparingPage />} />

          {/* -------------------------- 고객지원 -------------------------- */}
          {/* 공지사항  */}
          <Route path="notification" index element={<Notification />} />
          {/* 자주묻는질문  */}
          <Route path="faqSupport" index element={<FaqSupport />} />
          {/* 파생상품평가 관련 문의  */}
          <Route path="rcpsSupport" index element={<RcpsSupport />} />
          {/* 웹 개발 및 유지보수 문의  */}
          <Route path="webSupport" index element={<WebSupport />} />

          {/* -------------------------- 개발이력 -------------------------- */}
          {/* 웹 개발 및 유지보수 개발이력  */}
          <Route path="webCareer" index element={<WebCareer />} />
          {/* 파생상품 평가 이력  */}
          <Route path="rcpsCareer" index element={<RcpsCareer />} />
          {/* Mrc 내부통제 이력  */}
          {/* <Route path="mrcCareer" index element={<MrcCareer />} /> */}
          {/* 유지보수 이력  */}
          {/* <Route
            path="maintenanceCareer"
            index
            element={<MaintenanceCareer />}
          /> */}
          {/* -------------------------- 회사소개 -------------------------- */}
          {/* 기업정보  */}
          <Route
            path="companyInformation"
            index
            element={<CompanyInformation />}
          />
          {/* VALUES 파트너  */}
          <Route path="companyPartner" index element={<CompanyPartner />} />
          {/* BLOG  */}
          <Route path="companyBlog" index element={<CompanyBlog />} />

          {/* 공통 준비중 페이지  */}
          <Route path="preparing" index element={<PreparingPage />} />
        </Route>

        {/************************** Header 메뉴 END ***************************/}

        {/* Search Pages */}
        {/* <Route path="/" element={<LayoutSearch darkMode={darkMode} />}>
          <Route
            path="search"
            index
            element={<Search themeChange={themeChange} />}
          />{" "}
          <Route
            path="assetModel"
            index
            element={<AssetModel themeChange={themeChange} />}
          />
        </Route> */}
      </Routes>
    </>
  );
}

export default App;
