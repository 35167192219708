import React, { useState, useMemo, useRef } from "react";
import "../../../App.css";
import moment from "moment/moment";
import axiosInstance from "../../../utils/axios";
import { AgGridReact } from "ag-grid-react";

// 층별 면적
const FloorGrid = () => {
  const gridRef = useRef(); //그리드 객체 접근

  // GRID setting START -----------------------------------
  // grid Data 상태관리
  const [rowData, setRowData] = useState([
    {
      type: "지상3층",
      col1: "",
    },
    {
      type: "지상2층",
      col1: "",
    },
    {
      type: "지상1층",
      col1: "",
    },
    {
      type: "지하1층",
      col1: "",
    },
    {
      type: "지하2층",
      col1: "",
    },
  ]);

  // 한 행의 데이터 상태관리
  const [selectRowData, setSelectRowData] = useState([]);

  // 선택된 행의 데이터 가져오는 event
  const onSelectionChanged = (event) => {
    setSelectRowData(event.api.getSelectedRows());
    //console.log(event.api.getSelectedRows());
  };

  // 컬럼 정의 START
  const [colDefs, setColDefs] = useState([
    // {
    //   field: "",
    //   width: "50px",
    //   headerCheckboxSelection: true,
    //   checkboxSelection: true,
    //   showDisabledCheckboxes: true,
    //   cellEditor: "agTextCellEditor",
    //   cellEditorParams: {
    //     maxLength: 20,
    //   },
    //   editable: false,
    // },

    { headerName: "구분", field: "type", flex: 1, minWidth: 120 },
    {
      headerName: "면적",
      children: [
        {
          headerName: "계약면적",
          field: "col1",
          minWidth: 50,
        },
        {
          headerName: "전용면적",
          field: "col1",
          minWidth: 50,
        },
        {
          headerName: "용도",
          field: "col1",
          minWidth: 50,
        },
        {
          headerName: "기계, 전기실",
          field: "col1",
          minWidth: 50,
        },
        {
          headerName: "계단실",
          field: "col1",
          minWidth: 50,
        },
        {
          headerName: "합계",
          field: "col1",
          minWidth: 50,
        },
      ],
    },
    {
      headerName: "면적(평)",
      children: [
        {
          headerName: "계약면적",
          field: "col1",
          minWidth: 50,
        },
        {
          headerName: "전용면적",
          field: "col1",
          minWidth: 50,
        },
        {
          headerName: "용도",
          field: "col1",
          minWidth: 50,
        },
        {
          headerName: "기계, 전기실",
          field: "col1",
          minWidth: 50,
        },
        {
          headerName: "계단실",
          field: "col1",
          minWidth: 50,
        },
        {
          headerName: "합계",
          field: "col1",
          minWidth: 50,
        },
      ],
    },
  ]);
  // 컬럼 정의 END

  // 컬럼 기본값 정의
  const defaultColDef = useMemo(() => {
    return {
      width: "120px",
      editable: true, //글 수정 방지
      headerClass: "centered",
      cellClass: "centered",
      suppressMovable: true, // 컬럼 이동 방지
      sortable: false, // 정렬 방지
    };
  }, []);

  // GRID setting END -----------------------------------

  const pinnedBottomRowData = useMemo(() => {
    return [
      {
        type: "Total",
        col1: "",
      },
    ];
  }, []);

  return (
    <section className="h-auto">
      {/* ag grid setting */}
      <div className="h-auto my-5">
        <p className="text-sm p-1">층별 면적</p>
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: "350px", width: "100%" }} // the grid will fill the size of the parent container
        >
          <AgGridReact
            ref={gridRef}
            className="text-center 2xl:text-[13px] md:text-[12px] text-[11px]"
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            singleClickEdit={true}
            rowDrag={false} //행 드래그 방지
            suppressDragLeaveHidesColumns={true} // 컬럼 Leave 방지
            onSelectionChanged={onSelectionChanged} //선택된 행 데이터 가져오기
            overlayNoRowsTemplate="조회된 데이터가 없습니다."
            pinnedBottomRowData={pinnedBottomRowData}
          />
        </div>
      </div>
      {/* ag grid end */}
    </section>
  );
};

export default FloorGrid;
