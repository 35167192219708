import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { FiMessageCircle } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import WOW from "wowjs";

// VALUATION 자동화 - FIVS 특허완료
const PatentCompleted = () => {
  useEffect(() => {
    window.wow = new WOW.WOW({
      live: false,
    });

    window.wow.init({
      offset: 50,
    });
  }, []);

  return (
    <div className="wow fadeInUp">
      {/* title */}
      <div className="mb-2 border-b-[2px] border-stroke">
        <h2 className="font-bold md:text-[30px] text-[26px] mb-2">
          FIVS 특허완료
        </h2>
      </div>
      <div className="my-6 md:mb-20 mb-10">
        <div className="font-semibold md:text-[17px] text-[16px]">
          파생상품 평가 방법 및 솔루션과 관련하여 밸류즈(VALUES) 만의 평가
          방법론이 적용된 기술에 대한 특허등록이 완료되었습니다.
        </div>
      </div>
      {/* <div
                className="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                data-wow-delay=".2s"
              > */}
      {/* desciption */}
      <div
        className="wow fadeInUp grid md:grid-cols-2 grid-cols-1 gap-6 mb-24 "
        data-wow-delay=".2s"
      >
        <div className="py-4">
          <label className="text-gray-900 dark:text-gray-200 md:text-[16px] text-[15px]">
            <div className="my-1.5">
              VALUES는 2021년 초부터 파생상품평가에 대한 자동화 시스템 FIVS를
              개발하기 시작했고,
              <br /> 2024년 특허를 완료했습니다.
            </div>
            <div className="my-1.5">
              VALUES가 개발한 FIVS 시스템은 상환전환우선주(RCPS), 전환사채(CB),
              교환사채(EB), 신주인수권부사채(BW), 스톡옵션(SO) 등 5가지 대표
              항목이 있습니다.
            </div>
            <div className="my-1.5">
              이 항목들에 대한 계산, 데이터 불러오기, 결과저장과 검증 리스트,
              일자커브다운과 결과파일, 조서자동화와 보고서까지의 다양한 기능을
              이용하실 수 있습니다.
            </div>
          </label>
        </div>

        {/* <div className="hidden md:block"> */}
        <div className="">
          <img
            src="/images/about/fivsPatentCompleted.png"
            alt="About"
            className="dark:hidden"
          />
          <img
            src="/images/about/fivsPatentCompleted.png"
            alt="About"
            className="hidden dark:block"
          />
        </div>
      </div>
    </div>
  );
};

export default PatentCompleted;
