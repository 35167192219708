import React, { useState } from "react";
import axiosInstance from "../../../utils/axios";
import ProgressPanel from "../../../commonFunctions/ProgressPanel";

// 문의 하기
const SendMessage = () => {
  // 로딩 상태 체크 관리
  const [loading, setLoading] = useState(false);

  // 문의 전송 상태관리
  const [sendInfo, setSendInfo] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    content: "",
  });

  // 공통 input 이벤트
  const commonInput = (e) => {
    const { name, value } = e.target;

    setSendInfo((prevState) => ({
      ...prevState,
      [name]: value + "",
    }));
  };

  //문의 전송 이벤트
  const handleSendMessage = async () => {
    if (sendInfo.name === "") {
      alert("이름을 입력하시기 바랍니다.");
      return;
    }
    if (sendInfo.subject === "") {
      alert("제목을 입력하시기 바랍니다.");
      return;
    }
    if (sendInfo.email === "") {
      alert("이메일을 입력하시기 바랍니다.");
      return;
    }
    if (sendInfo.subject === "") {
      alert("제목을 입력하시기 바랍니다.");
      return;
    }
    if (sendInfo.phone === "") {
      alert("핸드폰 번호를 입력하시기 바랍니다.");
      return;
    }
    if (sendInfo.content === "") {
      alert("내용을 입력하시기 바랍니다.");
      return;
    }

    const body = {
      ...sendInfo,
    };
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `/comm/index/saveContact`,
        body
      );
      if (response.data.success) {
        alert("문의 전송되었습니다.");

        // 초기화
        setSendInfo((prevState) => ({
          ...prevState,
          name: "",
          email: "",
          subject: "",
          phone: "",
          content: "",
        }));
        return;
      }
      setLoading(false);
      console.log(response.data.success);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="support" className="pt-[100px] pb-[110px]">
      {/* 로딩중일때 */}
      {loading && <ProgressPanel />}
      <div className="container">
        <div
          className="wow fadeInUp mx-auto mb-10 max-w-[690px] text-center"
          data-wow-delay=".2s"
        >
          <h2 className="text-3xl font-bold text-black dark:text-white sm:text-4xl md:text-[44px] md:leading-tight">
            문의하기
          </h2>
          <h5 className="my-4">Contact Us</h5>
          <p className="text-base text-body">
            {" "}
            웹 개발 및 유지보수 또는 파생상품관련 문의는 아래 입력요소를 통해
            하실 수 있습니다.
          </p>
        </div>
      </div>

      <div className="container">
        <div
          className="wow fadeInUp mx-auto w-full max-w-[925px] rounded-lg bg-[#F8FAFB] px-8 py-10 shadow-card dark:bg-[#15182B] dark:shadow-card-dark sm:px-10"
          data-wow-delay=".3s"
        >
          <div className="-mx-[22px] flex flex-wrap">
            <div className="w-full px-[22px] md:w-1/2">
              <div className="mb-8">
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={commonInput}
                  value={sendInfo?.name || ""}
                  placeholder="성함을 입력하시기 바랍니다."
                  className="w-full rounded border border-stroke bg-white py-4 px-[30px] text-base text-body outline-none focus:border-primary dark:border-[#34374A] dark:bg-[#2A2E44] dark:focus:border-primary"
                />
              </div>
            </div>

            <div className="w-full px-[22px] md:w-1/2">
              <div className="mb-8">
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  onChange={commonInput}
                  value={sendInfo?.subject || ""}
                  placeholder="제목을 입력하시기 바랍니다. "
                  className="w-full rounded border border-stroke bg-white py-4 px-[30px] text-base text-body outline-none focus:border-primary dark:border-[#34374A] dark:bg-[#2A2E44] dark:focus:border-primary"
                />
              </div>
            </div>

            <div className="w-full px-[22px] md:w-1/2">
              <div className="mb-8">
                <input
                  type="email"
                  name="email"
                  onChange={commonInput}
                  value={sendInfo?.email || ""}
                  id="email"
                  placeholder="이메일을 입력하시기 바랍니다."
                  className="w-full rounded border border-stroke bg-white py-4 px-[30px] text-base text-body outline-none focus:border-primary dark:border-[#34374A] dark:bg-[#2A2E44] dark:focus:border-primary"
                />
              </div>
            </div>

            <div className="w-full px-[22px] md:w-1/2">
              <div className="mb-8">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  onChange={commonInput}
                  value={sendInfo?.phone || ""}
                  placeholder="전화번호를 입력하시기 바랍니다."
                  className="w-full rounded border border-stroke bg-white py-4 px-[30px] text-base text-body outline-none focus:border-primary dark:border-[#34374A] dark:bg-[#2A2E44] dark:focus:border-primary"
                />
              </div>
            </div>

            <div className="w-full px-[22px]">
              <div className="mb-8">
                <textarea
                  rows="6"
                  name="content"
                  id="content"
                  onChange={commonInput}
                  value={sendInfo?.content || ""}
                  placeholder="메시지를 입력하시기 바랍니다."
                  className="w-full rounded border border-stroke bg-white py-4 px-[30px] text-base text-body outline-none focus:border-primary dark:border-[#34374A] dark:bg-[#2A2E44] dark:focus:border-primary"
                ></textarea>
              </div>
            </div>

            <div className="w-full px-[22px]">
              <div className="text-center">
                {/* <p className="mb-5 text-center text-base text-body">
                  문의하기 버튼을 클릭 시 당사의 약관 및 정책에 동의한 것으로
                  간주됩니다.
                </p> */}
                <button
                  // type="submit"
                  onClick={handleSendMessage}
                  className="inline-block rounded-md bg-[#68afa5] py-[14px] px-11 text-base font-medium text-white hover:bg-opacity-90"
                >
                  문의하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SendMessage;
