import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";

const NavigationMenuBar = ({ navBarList }) => {
  const { pathname } = useLocation();

  const [navName, setNavName] = useState({
    menuTitleValue: "",
    menuNameValue: "",
    to: "",
  });

  const handleNavName = () => {
    console.log(pathname);
    navBarList.map(({ menuTitle, menuList }) => {
      menuList.map(({ to, menuName }) => {
        if (to === pathname) {
          setNavName((prevState) => ({
            ...prevState,
            menuTitleValue: menuTitle,
            menuNameValue: menuName,
            to: to,
          }));
        }
      });
    });
  };
  useEffect(() => {
    handleNavName();
  }, [pathname]);

  return (
    <div className="pt-[56px] bg-gray-100 dark:bg-[#000000] border-b-[1px] dark:border-gray-700 border-stroke items-center">
      <div className="dark:text-gray-100 md:h-12 h-10 md:px-24 px-2 flex justify-end items-center md:gap-4 gap-1.5 md:text-[13px] text-[12px] text-gray-700">
        <div>
          <Link to={"/"}>MAIN</Link>
        </div>
        <FiChevronRight />
        <div>
          <label>{navName?.menuTitleValue}</label>
        </div>
        <FiChevronRight />
        <div>
          <label>{navName?.menuNameValue}</label>
        </div>
      </div>
    </div>
  );
};

export default NavigationMenuBar;
