import React, { useEffect, useState } from "react";
import axiosInstance from "../../../utils/axios";
import { Link } from "react-router-dom";
import { MdOutlineDateRange } from "react-icons/md";
import { BsPerson } from "react-icons/bs";

const newsList = [
  {
    idx: 1,
    title: "프로젝트 개발 및 유지보수 \n 500건 이상 돌파",
    desc: "VALUES의 개발 / 유지보수 / FIVS 시스템 활용이 500건이상 돌파되었습니다.",
    author: "관리자",
    writeDate: "2024. 03. 02",
  },
  {
    idx: 2,
    title: "FIVS MRC(내부통제) 개발 완료",
    desc: "VALUES 자체 솔루션 FIVS의 내부통제(MRC)의 기능이 추가되었습니다.",
    author: "관리자",
    writeDate: "2024. 5. 02",
  },
  {
    idx: 3,
    title: "FIVS 파생상품평가 자동화 특허 완료",
    desc: "2021년부터 만들기 시작된 VALUES의 FIVS 파생상품평가 시스템의 특허가 완료되었습니다.",
    author: "관리자",
    writeDate: "2024. 10. 09",
  },
];

// 공지사항
const News = () => {
  return (
    <section id="blog" className="pt-[110px] pb-[60px] my-14">
      <div className="container">
        <div
          className="wow fadeInUp mx-auto mb-14 max-w-[690px] text-center md:mb-[40px]"
          data-wow-delay=".2s"
        >
          <h2 className="text-3xl font-bold text-black dark:text-white sm:text-4xl md:text-[44px] md:leading-tight">
            공지사항
          </h2>
          <h4 className="my-3 dark:text-white"> News</h4>
          <p className="text-base text-body">
            VALUES의 새로운 소식 및 업데이트 현황을 볼 수 있습니다.
          </p>
        </div>
      </div>

      <div className="container overflow-hidden lg:max-w-[1250px]">
        <div className="text-center -mx-4 flex flex-wrap justify-center md:-mx-7 lg:-mx-5 xl:-mx-[35px]">
          {newsList.map(({ title, desc, author, writeDate, idx }, index) => {
            // console.log(index);
            return (
              <div
                key={index}
                className="w-full px-4 md:w-1/2 md:px-7 lg:w-1/3 lg:px-5 xl:px-[35px] "
              >
                <div className="wow fadeInUp mb-10" data-wow-delay=".2s">
                  <div className="relative overflow-hidden rounded ">
                    <img
                      src={`images/news/news${idx}.png`}
                      alt="news1.png"
                      className="w-full h-56 object-cover object-center"
                    />
                    {/* <div className="absolute top-0 left-0 flex h-full w-full items-end bg-gradient-3 px-[18px] py-4"> */}
                    {/* <div className="absolute top-0 left-0 flex h-full w-full items-end px-[18px] py-4">
                      <div className="-mx-[10px] flex flex-wrap items-center ">
                        <p className="mt-3 flex items-center px-[10px] text-base font-medium text-black dark:text-white">
                          <span className="mr-2">
                            <BsPerson size={21} />
                          </span>
                          {author}
                        </p>

                        <p className="mt-3 flex items-center px-[10px] text-base font-medium text-black dark:text-white">
                          <span className="mr-2">
                            <MdOutlineDateRange size={21} />
                          </span>
                          {writeDate}
                        </p>
                      </div>
                    </div> */}
                  </div>

                  <div className="mt-[20px]">
                    <h3>
                      <label
                        href="blog-details.html"
                        className="whitespace-pre-line mb-3 inline-block text-xl font-semibold text-black dark:text-stroke md:text-[22px] text-[20px]"
                      >
                        {title}
                      </label>
                    </h3>
                    <p className="text-base text-body">{desc}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default News;
