import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { FiMessageCircle } from "react-icons/fi";
import WOW from "wowjs";
import Partners from "../../LandingPage/Component/Partners";
import { Link } from "react-router-dom";
import { IoMdArrowForward } from "react-icons/io";
// 회사소개 - Blog
const CompanyBlog = () => {
  useEffect(() => {
    window.wow = new WOW.WOW({
      live: false,
    });

    window.wow.init({
      offset: 50,
    });
  }, []);

  return (
    <div className="">
      {/* title */}
      <div className="mb-2 border-b-[2px] border-stroke wow fadeInUp">
        <h2 className="font-bold md:text-[30px] text-[26px] mb-2">BLOG</h2>
      </div>
      <div className="my-6 md:mb-10 mb-6 wow fadeInUp">
        <div className="font-semibold md:text-[17px] text-[16px] my-1.5"></div>
        <div className="md:text-[16px] text-[15px] dark:text-gray-400 wow fadeInUp">
          VALUES는 다양한 블로그를 통해 고객과 소통하고 있습니다. 신기술 또는
          업데이트 현황을 블로그를 통해서도 보실 수 있습니다.
          <br />
        </div>
      </div>

      {/* semi - title */}
      <div className="wow fadeInUp mt-20 ">
        <h2 className="font-bold md:text-[28px] text-[24px]">BLOG 소개</h2>
        <label>
          최신 트렌드, 깊이 있는 인사이트, 그리고 실무 팁까지! <br />
          VALUES 블로그는 업계의 최신 정보와 유용한 가이드를 제공합니다.
          <br /> 누구나 쉽게 이해하고 실무에 활용할 수 있도록 다양한 콘텐츠를
          정기적으로 업데이트하고 있습니다.
        </label>
      </div>

      <div className="py-7 flex items-center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://blog.naver.com/values_fivs"
          className="mr-6 mb-6 inline-flex h-[50px] items-center rounded-lg bg-[#68afa5] py-[14px] px-[30px] text-white hover:bg-opacity-100 dark:bg-white dark:text-black dark:hover:bg-opacity-90"
        >
          블로그 바로가기 <IoMdArrowForward size="21" className="ml-1" />
        </a>
      </div>
      {/* desciption */}
      <div className="md:block hidden ">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-3 mb-24 wow fadeInUp">
          <div className="">
            <img
              src="/images/about/vlauesBlog.png"
              alt="vlauesBlog"
              className="dark:hidden"
            />
            <img
              src="/images/about/vlauesBlog.png"
              alt="vlauesBlog"
              className="hidden dark:block"
            />
          </div>
          <div>
            <img
              src="/images/about/valuesBlog3.png"
              alt="valuesBlog3"
              className="dark:hidden"
            />
            <img
              src="/images/about/valuesBlog3.png"
              alt="valuesBlog3"
              className="hidden dark:block"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyBlog;
