import React, { useEffect, useRef, useState } from "react";
import WOW from "wowjs";
import { IoBarChart } from "react-icons/io5";
import { useLocation } from "react-router-dom";
const WebCareer = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤
  }, [pathname]);

  useEffect(() => {
    window.wow = new WOW.WOW({
      live: false,
    });

    window.wow.init({
      offset: 50,
    });
  }, []);

  // 연도
  const texts = [
    "2024",
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
  ];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  // 감지할 div 요소들을 참조
  const sectionRefs = useRef([]);

  // 2024 이력
  const menuText2024 = [
    {
      date: "10 - 11",
      desc: "파생상품평가 FIVS 반응형 웹 - UI 및 시스템 업데이트 \n VALUES 반응형 웹 - UI 및 시스템 업데이트",
    },
    {
      date: "09",
      desc: "AMDC \n 치과 디자인 결제 연동 시스템 개발 \n 관리자 페이지 추가 개발",
    },
    {
      date: "04 - 08",
      desc: "VALUES 자체 솔루션 \n 파생상품평가 FIVS 시스템 \n MRC(내부통제) 자동화 시스템 개발",
    },
    {
      date: "03",
      desc: "누리참 \n 거래처 발송 웹 개발",
    },
    {
      date: "03",
      desc: "서울시립대학원 \n 원우수첩 주소록 모바일 웹 개발",
    },
    {
      date: "02",
      desc: "미래정보기술 \n MSSQL 연동 및 조회 시스템 개발",
    },
  ];
  // 2023 이력
  const menuText2023 = [
    {
      date: "11 - 12",
      desc: "블랭킷 \n 법원경매 크롤링 시스템 구축",
    },
    {
      date: "09 - 10",
      desc: "굿럭 \n Gluck 모바일 시스템 유지보수 \n 국내 체험 상품을 외국인 대상으로 소개하고, 결제를 통한 체험 예약 시스템 구축",
    },
    {
      date: "08",
      desc: "랜드맵스 \n 랜드맵스 신규 홈페이지 구축 \n GIS 기반 좌표계산 웹 시스템 개발 \n 좌표 데이터를 통한 데이터 산출 및 지도 시각화 \n 대시보드 형태의 관리자 페이지 구성",
    },
    {
      date: "02 - 04",
      desc: "VALUES 자체 솔루션 \n 파생상품평가 FIVS 시스템 \n 검증리스트, 일자커브다운, 보고서, 조서자동화 기능 완료",
    },
    {
      date: "02 - 04",
      desc: "LG화학 (LG CNS) \n LG화학 탄소중립시스템 고도화 \n 공통기능 개발 \n (공통코드정보, Main UI 개선, 결재/권한 관리 개발)",
    },
  ];
  // 2022 이력
  const menuText2022 = [
    {
      date: "10 - 12",
      desc: "VALUES 자체 솔루션 \n 파생상품평가 FIVS 시스템 \n 전환사채(CB), 스톡옵션(SO) 자동화 시스템 개발 \n 계산 및 불러오기 기능 완료",
    },
    {
      date: "08 - 12",
      desc: "한국자산평가 \n 하이투자증권 시장리스크(RMS) 시스템 구축 \n 주가, 금리, 환율 상품등의 시장리스크 관리 웹 시스템 구축 \n 상품평가엔진 연동 및 서버 운영",
    },
    {
      date: "07 - 08",
      desc: "한솔로지스틱스 \n 그리드 상 개인화 데이터 설정 / 발전량 모니터링 웹 개발 \n 추가 개발 및 유지보수 \n 운송 관리 시스템 추가 개발 및 유지보수 업무",
    },
    {
      date: "01 - 06",
      desc: "제이케이시스템즈 \n 2021년도 문화유산표준관리시스템 기능 고도화 개발 \n 국립중앙박물관 문화유산표준관리시스템 기능 고도화 개발",
    },
  ];
  // 2021 이력
  const menuText2021 = [
    {
      date: "09 - 11",
      desc: "한국자산평가 \n Risk Management System \n 주가, 금리, 환율, 상품등의 시장리스크 관리 웹 시스템 구축 \n 상품평가엔진 연동 및 서버 운영",
    },
    {
      date: "07 - 08",
      desc: "현대회계법인 \n 입출금 내역 검증 웹 전산 프로그램 디자인/개발 \n백엔드 API 개발 \n 프론트엔드 대시보드 화면 UI 개발",
    },
    {
      date: "05 - 06",
      desc: "인프라큐브 \n 양식장 태양 / 발전량 모니터링 웹 개발 \n 백엔드 설정 및 데이터 통계 \n백엔드 API 개발 \n 프론트엔드 대시보드 모니터링 화면 UI 개발 ",
    },
    {
      date: "01 - 04",
      desc: "한국자산평가 \n TR 시스템 유지보수 \n 백엔드 설정 및 데이터 통계 \n 백엔드 API 개발  \n 프론트엔드 화면 UI 개발 및 업무 유지보수 ",
    },
  ];
  // 2020 이력
  const menuText2020 = [
    {
      date: "08 - 12",
      desc: "금융감독원 \n 통계분석 차트 시각화 및 웹 개발 \n 백엔드 설정 및 데이터 통계 \n 백엔드 API 개발 \n 프론트엔드 통계 데이터 차트 시각화",
    },
    {
      date: "03 - 07",
      desc: "VALUES 자체 솔루션 \n 파생상품평가 FIVS 시스템 \n 상환전환 우선주(RCPS) 자동화 시스템 개발",
    },
  ];
  // 2019 이력
  const menuText2019 = [
    { date: "08", desc: "SK 증권 \n 시장리스크 시스템 재구축" },
    { date: "07", desc: "두원공과대학 \n 고교관리 웹 시스템 신규 구축" },
    {
      date: "05",
      desc: "제이사랑노인복지센터  \n 문제지 결제관리 시스템 신규 구축",
    },
    { date: "03", desc: "오션그룹 \n 학생관리 웹 시스템 신규 구축" },
    { date: "02", desc: "현대회계법인 \n 자금관리 웹 시스템 신규 구축" },
  ];
  // 2018 이력
  const menuText2018 = [
    {
      date: "01 - 03",
      desc: "(주) 한국자산평가 \n 선가지수 시스템 개발 \n 선박 지수 데이터 가공 및 관리 웹 시스템 신규 구축  \n 지수 통계 처리 및 관리자 시스템 개발",
    },
    {
      date: "01 - 12",
      desc: "(주) 한국자산평가 \n ELS 판매지원 시스템 \n 개발, 구축 \n 신한은행 타깃으로 한 ELS 상품 판매지원 시스템 신규 구축 \n ELS 대상 상품에 대한 상품 선정 및 사후 관리까지 체계화된 플로우를 제공",
    },
  ];
  // 2017 이력
  const menuText2017 = [
    {
      date: "07 - 12",
      desc: "(주) 한국자산평가 \n NMPS 비상장 주식 평가 시스템 \n 개발, 유지보수 \n 비상장 주식 평가 시스템 엔진 구축 및 연동 ",
    },
    {
      date: "04 - 06",
      desc: "(주) 한국자산평가 \n ELS 판매지원 시스템 개발 \n 정식 프로그램 개발 전 시연용 데모버전 웹 시스템 구축 \n 디자이너 협력을 통한 전반적인 레이아웃을 구성",
    },
    {
      date: "01 - 03",
      desc: "(주) 한국자산평가 \n ELS 지수 공시 웹 시스템 개발 \n 파생결합증권형 지수 계산 및 추이 통계 데이터 산출 웹 시스템 구축",
    },
  ];
  // 2011–2016 이력
  const menuText2011to2016 = [
    {
      date: "2016. 07 – 2017. 01",
      desc: "KB 손해보험 (상주) \n Risk Management System \n 개발, 구축 및 레이아웃 \n 주가, 금리, 환율, 상품등의 시장리스크 관리 웹 시스템 구축 \n 상품평가엔진 연동 및 서버 운영",
    },
    {
      date: "2015. 10 – 2016. 06",
      desc: "MG 손해보험 (상주) \n Risk Management System \n 개발, 구축 및 레이아웃 \n 주가, 금리, 환율, 상품등의 시장리스크 관리 웹 시스템 구축 \n 상품평가엔진 연동 및 서버 운영",
    },
    {
      date: "2015. 01 – 2015. 09",
      desc: "중소기업중앙회 (상주) \n KBIZ MRO (시장리스크 관리 시스템) \n 개발, 유지보수 \n 주가, 금리, 환율, 상품등의 시장리스크 관리 웹 시스템 구축 \n 상품평가엔진 연동 및 서버 운영",
    },
    {
      date: "2014. 05 – 2014. 12",
      desc: "(주) 한국자산평가 \n C-Master 비상장 주식 평가 시스템 \n 개발, 유지보수 \n 비상장 주식 예상 주가 산정 웹 시스템 신규 구축 \n 반응형 웹을 통한 PC/모바일 브라우저 호환 레이아웃 구성",
    },
    {
      date: "2013. 09 – 2014. 04",
      desc: "(주) 한국자산평가 \n B-Master 채권 정보 시스템 \n 개발, 시스템 마이그레이션 \n 구 시스템을 통한 신규시스템 구축 \n 웹 시스템 유지보수 및 서버 운영관리",
    },
    {
      date: "2011. 03 – 2013. 02",
      desc: "(주) 피앤피시큐어 \n 서버 엔지니어링 및 시스템 유지보수",
    },
  ];

  useEffect(() => {
    // Intersection Observer 설정
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // 특정 div가 화면에 보일 때 해당 인덱스에 맞는 텍스트로 변경
            const index = sectionRefs.current.indexOf(entry.target);
            // console.log(entry.target);
            // console.log(index);
            setCurrentTextIndex(index);
          }
        });
      },
      { threshold: 0.5 } // 요소가 50% 이상 보일 때 트리거
    );

    // 모든 sectionRefs 요소에 observer 연결
    sectionRefs.current.forEach((section) => observer.observe(section));

    // 컴포넌트가 언마운트될 때 observer 해제
    // return () => {
    //   sectionRefs.current.forEach((section) => observer.unobserve(section));
    // };
  }, []);

  // *** 스크롤 위치가 맨 밑에 도달하면 이미지를 숨기기 위한 코드 START ***
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = () => {
    // 스크롤 위치가 하단에 있는지 확인
    const scrollPosition = window.scrollY + window.innerHeight;
    const pageHeight = document.documentElement.scrollHeight;

    if (pageHeight - scrollPosition <= 120) {
      setIsBottom(true); // 하단에서 120px 위정도 도달하면 true로 설정
    } else {
      setIsBottom(false); // 하단이 아니면 false로 설정
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // *** 스크롤 위치가 맨 밑에 도달하면 이미지를 숨기기 위한 코드 END ***
  return (
    <div>
      {/* title */}
      {/* <h2 className="wow fadeInUp md:text-[15px] text-[14px] font-medium text-left mb-8 py-4 ">
        VALUES의 웹 개발 및 유지보수 이력을 보실 수 있습니다.
      </h2> */}

      <div className="grid md:grid-cols-2 grid-cols-1 ">
        {/* 연도 */}
        <div className="h-auto text-black md:block hidden">
          <div className="md:fixed md:top-16 p-40 w-1/2">
            {!isBottom && (
              <div className="flex-col">
                <label className="font-bold md:text-[130px] text-[#4b9488]">
                  {texts[currentTextIndex] === "2016" ? (
                    <label className="md:text-[70px]">By </label>
                  ) : (
                    ""
                  )}
                  {texts[currentTextIndex]}
                </label>
                <div className="flex justify-center wow fadeInLeft">
                  <img
                    src="/images/about/valuesCareer.png"
                    alt="valuesCareer"
                    className="w-[270px] inline px-1 dark:hidden"
                  />
                  <img
                    src="/images/about/valuesCareer2.png"
                    alt="valuesCareer2"
                    className="w-[270px]  px-1 dark:inline hidden"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* 모바일에서 보이는 이미지 */}
        <div className="wow fadeInUp md:hidden flex justify-center">
          <img
            src="/images/about/valuesCareer.png"
            alt="valuesCareer"
            className="w-4/5 inline px-1 my-2"
          />
        </div>

        <div className="wow fadeInUp ">
          {/* 2024 */}
          <div id="1" ref={(el) => (sectionRefs.current[0] = el)}>
            <div className="relative border-l border-gray-200 dark:border-gray-700">
              <div className="md:mb-36 mb-20 ml-14">
                {/* 모바일에서만 보이는 영역 */}
                <div className=" md:hidden block">
                  <div className="flex items-center">
                    <span className="flex absolute -left-2 justify-center items-center w-4 h-4 bg-[#57948b] rounded-full ring-8 ring-white dark:ring-gray-800 dark:bg-[#57948b]"></span>
                    <h3 className="md:flex items-center md:text-[32px] text-[28px] font-semibold text-gray-900 dark:text-white">
                      {texts[currentTextIndex]}
                    </h3>
                  </div>
                </div>
                {menuText2024.map(({ date, desc }) => {
                  return (
                    <div
                      className="py-4 mb-2"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      <label className="mb-1 md:text-[20px] text-[19px] font-medium text-black dark:text-gray-400">
                        {date}
                      </label>
                      <div className="my-1.5"></div>
                      <label className="md:text-[16.5px] text-[15px] text-gray-800 dark:text-gray-400 ">
                        {desc}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* 2023 */}
          <div id="2" ref={(el) => (sectionRefs.current[1] = el)}>
            <div className="relative border-l border-gray-200 dark:border-gray-700">
              <div className="md:mb-36 mb-20 ml-14">
                {/* 모바일에서만 보이는 영역 */}
                <div className=" md:hidden block">
                  <div className="flex items-center">
                    <span className="flex absolute -left-2 justify-center items-center w-4 h-4 bg-[#57948b] rounded-full ring-8 ring-white dark:ring-gray-800 dark:bg-[#57948b]"></span>
                    <h3 className="md:flex items-center md:text-[32px] text-[28px] font-semibold text-gray-900 dark:text-white">
                      {texts[currentTextIndex]}
                    </h3>
                  </div>
                </div>
                {menuText2023.map(({ date, desc }) => {
                  return (
                    <div
                      className="py-4 mb-2"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      <label className="mb-1 md:text-[18px] text-[17px] font-semibold text-black dark:text-gray-400">
                        {date}
                      </label>
                      <div className="my-1.5"></div>
                      <label className="md:text-[16.5px] text-[15px] text-gray-800 dark:text-gray-400">
                        {desc}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* 2022 */}
          <div id="3" ref={(el) => (sectionRefs.current[2] = el)}>
            <div className="relative border-l border-gray-200 dark:border-gray-700">
              <div className="md:mb-36 mb-20 ml-14">
                {/* 모바일에서만 보이는 영역 */}
                <div className=" md:hidden block">
                  <div className="flex items-center">
                    <span className="flex absolute -left-2 justify-center items-center w-4 h-4 bg-[#57948b] rounded-full ring-8 ring-white dark:ring-gray-800 dark:bg-[#57948b]"></span>
                    <h3 className="md:flex items-center md:text-[32px] text-[28px] font-semibold text-gray-900 dark:text-white">
                      {texts[currentTextIndex]}
                    </h3>
                  </div>
                </div>
                {menuText2022.map(({ date, desc }) => {
                  return (
                    <div
                      className="py-4 mb-2"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      <label className="mb-1 md:text-[18px] text-[17px] font-semibold text-black dark:text-gray-400">
                        {date}
                      </label>
                      <div className="my-1.5"></div>
                      <label className="md:text-[16.5px] text-[15px] text-gray-800 dark:text-gray-400 ">
                        {desc}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* 2021 */}
          <div id="4" ref={(el) => (sectionRefs.current[3] = el)}>
            <div className="relative border-l border-gray-200 dark:border-gray-700">
              <div className="md:mb-36 mb-20 ml-14">
                {/* 모바일에서만 보이는 영역 */}
                <div className=" md:hidden block">
                  <div className="flex items-center">
                    <span className="flex absolute -left-2 justify-center items-center w-4 h-4 bg-[#57948b] rounded-full ring-8 ring-white dark:ring-gray-800 dark:bg-[#57948b]"></span>
                    <h3 className="md:flex items-center md:text-[32px] text-[28px] font-semibold text-gray-900 dark:text-white">
                      {texts[currentTextIndex]}
                    </h3>
                  </div>
                </div>
                {menuText2021.map(({ date, desc }) => {
                  return (
                    <div
                      className="py-4 mb-2"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      <label className="mb-1 md:text-[18px] text-[17px] font-semibold text-black dark:text-gray-400">
                        {date}
                      </label>
                      <div className="my-1.5"></div>
                      <label className="md:text-[16.5px] text-[15px] text-gray-800 dark:text-gray-400 ">
                        {desc}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* 2020 */}
          <div id="5" ref={(el) => (sectionRefs.current[4] = el)}>
            <div className="relative border-l border-gray-200 dark:border-gray-700">
              <div className="md:mb-36 mb-20 ml-14">
                {/* 모바일에서만 보이는 영역 */}
                <div className=" md:hidden block">
                  <div className="flex items-center">
                    <span className="flex absolute -left-2 justify-center items-center w-4 h-4 bg-[#57948b] rounded-full ring-8 ring-white dark:ring-gray-800 dark:bg-[#57948b]"></span>
                    <h3 className="md:flex items-center md:text-[32px] text-[28px] font-semibold text-gray-900 dark:text-white">
                      {texts[currentTextIndex]}
                    </h3>
                  </div>
                </div>
                {menuText2020.map(({ date, desc }) => {
                  return (
                    <div
                      className="py-4 mb-2"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      <label className="mb-1 md:text-[18px] text-[17px] font-semibold text-black dark:text-gray-400">
                        {date}
                      </label>
                      <div className="my-1.5"></div>
                      <label className="md:text-[16.5px] text-[15px] text-gray-800 dark:text-gray-400 ">
                        {desc}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* 2019 */}
          <div id="6" ref={(el) => (sectionRefs.current[5] = el)}>
            <div className="relative border-l border-gray-200 dark:border-gray-700">
              <div className="md:mb-36 mb-20 ml-14">
                {/* 모바일에서만 보이는 영역 */}
                <div className=" md:hidden block">
                  <div className="flex items-center">
                    <span className="flex absolute -left-2 justify-center items-center w-4 h-4 bg-[#57948b] rounded-full ring-8 ring-white dark:ring-gray-800 dark:bg-[#57948b]"></span>
                    <h3 className="md:flex items-center md:text-[32px] text-[28px] font-semibold text-gray-900 dark:text-white">
                      {texts[currentTextIndex]}
                    </h3>
                  </div>
                </div>
                {menuText2019.map(({ date, desc }) => {
                  return (
                    <div
                      className="py-4 mb-2"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      <label className="mb-1 md:text-[18px] text-[17px] font-semibold text-black dark:text-gray-400">
                        {date}
                      </label>
                      <div className="my-1.5"></div>
                      <label className="md:text-[16.5px] text-[15px] text-gray-800 dark:text-gray-400 ">
                        {desc}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* 2018 */}
          <div id="7" ref={(el) => (sectionRefs.current[6] = el)}>
            <div className="relative border-l border-gray-200 dark:border-gray-700">
              <div className="md:mb-36 mb-20 ml-14">
                {/* 모바일에서만 보이는 영역 */}
                <div className=" md:hidden block">
                  <div className="flex items-center">
                    <span className="flex absolute -left-2 justify-center items-center w-4 h-4 bg-[#57948b] rounded-full ring-8 ring-white dark:ring-gray-800 dark:bg-[#57948b]"></span>
                    <h3 className="md:flex items-center md:text-[32px] text-[28px] font-semibold text-gray-900 dark:text-white">
                      {texts[currentTextIndex]}
                    </h3>
                  </div>
                </div>
                {menuText2018.map(({ date, desc }) => {
                  return (
                    <div
                      className="py-4 mb-2"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      <label className="mb-1 md:text-[18px] text-[17px] font-semibold text-black dark:text-gray-400">
                        {date}
                      </label>
                      <div className="my-1.5"></div>
                      <label className="md:text-[16.5px] text-[15px] text-gray-800 dark:text-gray-400 ">
                        {desc}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* 2017 */}
          <div id="8" ref={(el) => (sectionRefs.current[7] = el)}>
            <div className="relative border-l border-gray-200 dark:border-gray-700">
              <div className="md:mb-36 mb-20 ml-14">
                {/* 모바일에서만 보이는 영역 */}
                <div className=" md:hidden block">
                  <div className="flex items-center">
                    <span className="flex absolute -left-2 justify-center items-center w-4 h-4 bg-[#57948b] rounded-full ring-8 ring-white dark:ring-gray-800 dark:bg-[#57948b]"></span>
                    <h3 className="md:flex items-center md:text-[32px] text-[28px] font-semibold text-gray-900 dark:text-white">
                      {texts[currentTextIndex]}
                    </h3>
                  </div>
                </div>
                {menuText2017.map(({ date, desc }) => {
                  return (
                    <div
                      className="py-4 mb-2"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      <label className="mb-1 md:text-[18px] text-[17px] font-semibold text-black dark:text-gray-400">
                        {date}
                      </label>
                      <div className="my-1.5"></div>
                      <label className="md:text-[16.5px] text-[15px] text-gray-800 dark:text-gray-400 ">
                        {desc}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* 2011–2016 */}
          <div id="9" ref={(el) => (sectionRefs.current[8] = el)}>
            <div className="relative border-l border-gray-200 dark:border-gray-700">
              <div className="md:mb-36 mb-20 ml-14">
                {/* 모바일에서만 보이는 영역 */}
                <div className=" md:hidden block">
                  <div className="flex items-center">
                    <span className="flex absolute -left-2 justify-center items-center w-4 h-4 bg-[#57948b] rounded-full ring-8 ring-white dark:ring-gray-800 dark:bg-[#57948b]"></span>
                    <h3 className="md:flex items-center md:text-[32px] text-[28px] font-semibold text-gray-900 dark:text-white">
                      {texts[currentTextIndex]}
                    </h3>
                  </div>
                </div>
                {menuText2011to2016.map(({ date, desc }) => {
                  return (
                    <div
                      className="py-4 mb-2"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      <label className="mb-1 md:text-[18px] text-[17px] font-semibold text-black dark:text-gray-400">
                        {date}
                      </label>
                      <div className="my-1.5"></div>
                      <label className="md:text-[16.5px] text-[15px] text-gray-800 dark:text-gray-400 ">
                        {desc}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebCareer;
