import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RiArrowDownSLine } from "react-icons/ri";
import { RiArrowUpSLine } from "react-icons/ri";
import { IoMdCloseCircle } from "react-icons/io";
const Header = ({ themeChange, navBarList }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤
  }, [pathname]);

  const onClickTheme = (e) => {
    themeChange();
  };

  // 페이지 이동시 드롭다운 메뉴 닫기

  // useEffect(() => {}, [pathname]);

  // 현재 활성화된 탭을 관리하는 상태 변수
  const [activeTab, setActiveTab] = useState("");

  // 모바일 네비게이션 토글
  const [mobileNavigationOpen, setMobileNavigationOpen] = useState(false);

  // 흐림 상태 관리
  const [isBlurred, setIsBlurred] = useState(false);

  // 모바일일때 토글 오픈 이벤트
  const handleMobileToggle = () => {
    setMobileNavigationOpen(!mobileNavigationOpen);
  };

  const handleClickMenu = (menu) => {
    console.log(menu);
    // 같은거 클릭 했을때는 빈값 보내서 닫기
    setActiveTab((prev) => (!prev ? menu : ""));

    // 다른 메뉴 클릭했을때 menu 보내주기
    if (activeTab !== menu) {
      setActiveTab(menu);
      setIsBlurred(true); // 탭 클릭 시 흐림 토글
    } else {
      setIsBlurred(false);
    }
  };

  // ******************* 헤더 영역 밖에 클릭시 이벤트 START *******************
  const refHeader = useRef(null);
  // div 외부 클릭을 감지하는 함수
  const handleClickOutside = (event) => {
    if (refHeader.current && !refHeader.current.contains(event.target)) {
      setActiveTab(""); // 바깥 영역 클릭 시 닫기
      setIsBlurred(false); // 흐리게 false
    }
  };

  useEffect(() => {
    // 마우스 다운 이벤트 등록
    document.addEventListener("mousedown", handleClickOutside);

    // 언마운트 시 이벤트 제거
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // ******************* 헤더 영역 밖에 클릭시 이벤트 END *******************

  // Link 클릭시 header영역 닫기
  const handleHeaderClose = () => {
    setActiveTab("");
    setIsBlurred(false); // 흐리게 false
  };

  return (
    <div>
      {/* nabvar 메뉴 클릭시 바깥 여백 흐림처리 */}
      <div
        className={`${
          isBlurred
            ? "w-full h-[100vh] bg-black opacity-40 fixed top-[100px] z-30"
            : ""
        }`}
      ></div>
      <header
        ref={refHeader}
        // onMouseLeave={handleMouseLeave}
        className={`border-b-[1px] navbar fixed top-0 pt-2 left-0 z-50 w-full bg-white duration-300 dark:border-stroke-dark dark:bg-black`}
      >
        <div className="container relative max-w-[1400px]">
          <div className="flex items-center justify-between">
            <div className="block py-4 lg:py-0">
              <a href="/" className="block max-w-[145px] sm:max-w-[180px]">
                <img
                  // src={`${process.env.PUBLIC_URL}/images/logo/main-logo-new.png`}
                  src={`${process.env.PUBLIC_URL}/images/logo/valuesLogo.png`}
                  alt="logo"
                  className="block dark:hidden"
                />
                <img
                  // src={`${process.env.PUBLIC_URL}/images/logo/main-logo-new-removebg-preview.png`}
                  src={`${process.env.PUBLIC_URL}/images/logo/valuesLogo.png`}
                  alt="logo"
                  className="hidden dark:block"
                />
              </a>
            </div>
            {/* 햄버거 모양 - 모바일일때 메뉴 버튼 */}
            <button
              onClick={handleMobileToggle}
              className="absolute right-4 top-1/2 z-50 flex h-10 w-10 -translate-y-1/2 flex-col items-center justify-center space-y-[6px] font-bold lg:hidden"
            >
              <span className="block h-[2px] w-7 bg-black dark:bg-white"></span>
              <span className="block h-[2px] w-7 bg-black dark:bg-white"></span>
              <span className="block h-[2px] w-7 bg-black dark:bg-white"></span>
            </button>

            <div className="menu-wrapper relative hidden justify-between lg:flex">
              <button
                className="navbarClose fixed top-10 right-10 z-[9999] flex h-10 w-10 flex-col items-center justify-center font-bold lg:hidden"
                name="navbarClose"
                aria-label="navbarClose"
              >
                <span className="block h-[2px] w-7 rotate-45 bg-black dark:bg-white"></span>
                <span className="-mt-[2px] block h-[2px] w-7 -rotate-45 bg-black dark:bg-white"></span>
              </button>

              <nav className="lg:block hidden fixed top-0 left-0 z-[999]  h-screen w-full items-center justify-center bg-white bg-opacity-95 text-center backdrop-blur-sm dark:bg-black dark:bg-opacity-95 lg:static lg:h-auto lg:w-max md:bg-transparent md:backdrop-blur-none md:dark:bg-transparent">
                <div>
                  {/* 네비게이션 바 */}
                  <nav className="">
                    <div className="container mx-auto flex justify-between items-center">
                      <ul className="flex space-x-4 gap-7">
                        {navBarList.map(({ menuTitle }, index) => {
                          return (
                            <li
                              key={index}
                              className={` ${
                                activeTab === menuTitle
                                  ? " border-b-[4.5px]  border-[#32c5af] "
                                  : ""
                              } py-6 2xl:text-[17.5px] md:text-[17px] text-[16px] font-semibold `}
                            >
                              <button
                                className="text-black dark:text-white"
                                onClick={() => handleClickMenu(menuTitle)}
                              >
                                {menuTitle}
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </nav>
                </div>
              </nav>
            </div>

            <div className="mr-[60px] flex items-center justify-end lg:mr-0">
              <label
                htmlFor="themeSwitcher"
                className="inline-flex cursor-pointer items-center"
                aria-label="themeSwitcher"
                name="themeSwitcher"
              >
                <input
                  type="checkbox"
                  name="themeSwitcher"
                  id="themeSwitcher"
                  className="sr-only"
                  onClick={onClickTheme}
                />
                <span className="hidden dark:block">
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_420_119)">
                      <path
                        d="M8.40276 5.88205C8.40259 7.05058 8.75048 8.19267 9.40204 9.16268C10.0536 10.1327 10.9793 10.8866 12.0611 11.3284C13.143 11.7701 14.3318 11.8796 15.4761 11.6429C16.6204 11.4062 17.6683 10.8341 18.4861 9.99941V10.0834C18.4861 14.7243 14.7242 18.4862 10.0833 18.4862C5.44247 18.4862 1.68054 14.7243 1.68054 10.0834C1.68054 5.44259 5.44247 1.68066 10.0833 1.68066H10.1673C9.60775 2.22779 9.16333 2.88139 8.86028 3.60295C8.55722 4.32451 8.40166 5.09943 8.40276 5.88205V5.88205ZM3.3611 10.0834C3.36049 11.5833 3.86151 13.0403 4.78444 14.2226C5.70737 15.4049 6.99919 16.2446 8.45437 16.608C9.90954 16.9715 11.4445 16.8379 12.8149 16.2284C14.1854 15.6189 15.3127 14.5686 16.0174 13.2446C14.7632 13.54 13.4543 13.5102 12.215 13.1578C10.9756 12.8054 9.84679 12.1422 8.93568 11.2311C8.02457 10.32 7.36136 9.19119 7.00898 7.95181C6.6566 6.71243 6.62672 5.40357 6.92219 4.1494C5.84629 4.72259 4.94652 5.57759 4.31923 6.62288C3.69194 7.66817 3.36074 8.86438 3.3611 10.0834V10.0834Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_420_119">
                        <rect width="20.1667" height="20.1667" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span className="block dark:hidden">
                  <svg
                    width="23"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1_11114)">
                      <path
                        d="M11.5658 16.4998C10.1071 16.4998 8.70815 15.9204 7.6767 14.8889C6.64525 13.8575 6.06579 12.4585 6.06579 10.9998C6.06579 9.54115 6.64525 8.1422 7.6767 7.11075C8.70815 6.0793 10.1071 5.49984 11.5658 5.49984C13.0245 5.49984 14.4234 6.0793 15.4549 7.11075C16.4863 8.1422 17.0658 9.54115 17.0658 10.9998C17.0658 12.4585 16.4863 13.8575 15.4549 14.8889C14.4234 15.9204 13.0245 16.4998 11.5658 16.4998ZM11.5658 14.6665C12.5382 14.6665 13.4709 14.2802 14.1585 13.5926C14.8461 12.9049 15.2325 11.9723 15.2325 10.9998C15.2325 10.0274 14.8461 9.09475 14.1585 8.40711C13.4709 7.71948 12.5382 7.33317 11.5658 7.33317C10.5933 7.33317 9.6607 7.71948 8.97306 8.40711C8.28543 9.09475 7.89912 10.0274 7.89912 10.9998C7.89912 11.9723 8.28543 12.9049 8.97306 13.5926C9.6607 14.2802 10.5933 14.6665 11.5658 14.6665ZM10.6491 0.916504H12.4825V3.6665H10.6491V0.916504ZM10.6491 18.3332H12.4825V21.0832H10.6491V18.3332ZM3.78787 4.51809L5.08404 3.22192L7.02829 5.16617L5.73212 6.46234L3.78787 4.519V4.51809ZM16.1033 16.8335L17.3995 15.5373L19.3437 17.4816L18.0475 18.7778L16.1033 16.8335ZM18.0475 3.221L19.3437 4.51809L17.3995 6.46234L16.1033 5.16617L18.0475 3.22192V3.221ZM5.73212 15.5373L7.02829 16.8335L5.08404 18.7778L3.78787 17.4816L5.73212 15.5373ZM21.6491 10.0832V11.9165H18.8991V10.0832H21.6491ZM4.23245 10.0832V11.9165H1.48245V10.0832H4.23245Z"
                        fill="#181C31"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_11114">
                        <rect
                          width="22"
                          height="22"
                          fill="white"
                          transform="translate(0.565796)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </label>
              {/* <a
                href="/login"
                className="hidden py-[10px] px-6 text-base font-medium text-black hover:text-primary dark:text-white dark:hover:text-primary sm:inline-block"
              >
                {" "}
              </a> */}

              {/* <a
                href="/register"
                className="hidden rounded-md bg-[#61a399] py-[10px] px-[30px] text-base font-medium text-white hover:bg-opacity-90 sm:inline-block"
              >
                소개영상
              </a> */}
              {/* <a
                target="_blank"
                href="https://www.fivs.co.kr"
                className="ml-1 hidden rounded-lg bg-[#61a399] py-[10px] px-[30px] text-base font-medium text-white hover:bg-opacity-90 sm:inline-block"
              >
                FIVS 이동
              </a> */}
            </div>
          </div>
        </div>
        {/********************** PC, 패드 탭 내용 START **********************/}

        {activeTab === "VALUATION 자동화" ? (
          <div className="w-full rounded border-t-[1px] border-stroke h-[330px] z-10 text-black dark:text-white">
            <div className="grid md:grid-cols-4 container py-10 gap-16">
              {/* grid 1/4 */}
              <div className="col-span-1">
                <div className="items-center">
                  <div className="flex justify-center mb-1">
                    <img
                      src="/images/icon/valuesImage2.png"
                      alt="valuesImage"
                      className="w-[110px] rounded-full"
                    />
                  </div>
                  <div className="flex justify-center md:text-[20px] text-[19px] font-semibold mb-2">
                    <label>VALUATION 자동화</label>
                  </div>
                </div>
                <div className="flex justify-center md:text-[14px] text-[13px] text-gray-500 dark:text-gray-300">
                  <label className="text-center">
                    평가부터 보고서까지 one-stop으로 사용자가 손쉽게 평가할 수
                    있는 툴을 제공합니다.
                  </label>
                </div>
              </div>
              {/* grid 3/4 */}
              <div className="col-span-3 grid grid-cols-3 gap-x-12 gap-y-10">
                {/* 3등분 */}
                {navBarList
                  .filter(({ menuTitle }) => menuTitle === activeTab)
                  .map(({ menuList }) => {
                    return menuList.map(({ menuName, desc, to }, index) => {
                      return (
                        <div key={index}>
                          <div className="cursor-pointer md:text-[17px] text-[16px] font-semibold pb-2 border-b-[1px] border-stone-200">
                            <Link to={to} onClick={handleHeaderClose}>
                              {menuName}
                            </Link>
                          </div>
                          <div className="py-2 md:text-[14px] text-[13px] text-gray-600 dark:text-gray-300">
                            <label>{desc}</label>
                          </div>
                        </div>
                      );
                    });
                  })}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {activeTab === "IT 개발 & 솔루션" ? (
          <div className="w-full rounded border-t-[1px] border-stroke h-[330px] z-10 text-black dark:text-white">
            <div className="grid md:grid-cols-4 container py-10 gap-16">
              {/* grid 1/4 */}
              <div className="col-span-1">
                <div className="items-center">
                  <div className="flex justify-center mb-1">
                    <img
                      src="/images/icon/valuesImage6.png"
                      alt="valuesImage"
                      className="w-[110px] rounded-full"
                    />
                  </div>
                  <div className="flex justify-center md:text-[20px] text-[19px] font-semibold mb-2">
                    <label>IT 개발 & 솔루션</label>
                  </div>
                </div>
                <div className="flex justify-center md:text-[14px] text-[13px] text-gray-500 dark:text-gray-300">
                  <label className="text-center">
                    VALUES는 IT 개발과 통합 솔루션, 웹 유지보수 등을 통해 기업의
                    성장을 도울 수 있습니다.
                  </label>
                </div>
              </div>
              {/* grid 3/4 */}
              <div className="col-span-3 grid grid-cols-3 gap-x-12 gap-y-10">
                {/* 3등분 */}
                {navBarList
                  .filter(({ menuTitle }) => menuTitle === activeTab)
                  .map(({ menuList }) => {
                    return menuList.map(({ menuName, desc, to }, index) => {
                      return (
                        <div key={index}>
                          <div className="cursor-pointer md:text-[17px] text-[16px] font-semibold pb-2 border-b-[1px] border-stone-200">
                            <Link to={to} onClick={handleHeaderClose}>
                              {menuName}
                            </Link>
                          </div>
                          <div className="py-2 md:text-[14px] text-[13px] text-gray-600 dark:text-gray-300">
                            <label>{desc}</label>
                          </div>
                        </div>
                      );
                    });
                  })}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {activeTab === "고객지원" ? (
          <div className="w-full rounded border-t-[1px] border-stroke h-[330px] z-10 text-black dark:text-white">
            <div className="grid md:grid-cols-4 container py-10 gap-16">
              {/* grid 1/4 */}
              <div className="col-span-1">
                <div className="items-center">
                  <div className="flex justify-center mb-1">
                    <img
                      src="/images/icon/valuesImage4.png"
                      alt="valuesImage"
                      className="w-[110px] rounded-full"
                    />
                  </div>
                  <div className="flex justify-center md:text-[20px] text-[19px] font-semibold mb-2">
                    <label>고객지원</label>
                  </div>
                </div>
                <div className="flex justify-center md:text-[14px] text-[13px] text-gray-500 dark:text-gray-300">
                  <label className="text-center">
                    평가 관련 문의 또는 웹 솔루션 개발과 유지보수에 대한 문의를
                    받고 있습니다.
                  </label>
                </div>
              </div>
              {/* grid 3/4 */}
              <div className="col-span-3 grid grid-cols-3 gap-x-12 gap-y-10">
                {/* 3등분 */}
                {navBarList
                  .filter(({ menuTitle }) => menuTitle === activeTab)
                  .map(({ menuList }) => {
                    return menuList.map(({ menuName, desc, to }, index) => {
                      return (
                        <div key={index}>
                          <div className="cursor-pointer md:text-[17px] text-[16px] font-semibold pb-2 border-b-[1px] border-stone-200">
                            <Link to={to} onClick={handleHeaderClose}>
                              {menuName}
                            </Link>
                          </div>
                          <div className="py-2 md:text-[14px] text-[13px] text-gray-600 dark:text-gray-300">
                            <label>{desc}</label>
                          </div>
                        </div>
                      );
                    });
                  })}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {activeTab === "개발이력" ? (
          <div className="w-full rounded border-t-[1px] border-stroke h-[330px] z-10 text-black dark:text-white">
            <div className="grid md:grid-cols-4 container py-10 gap-16">
              {/* grid 1/4 */}
              <div className="col-span-1">
                <div className="items-center">
                  <div className="flex justify-center mb-1">
                    <img
                      src="/images/icon/valuesImage7.png"
                      alt="valuesImage"
                      className="w-[110px] rounded-full"
                    />
                  </div>
                  <div className="flex justify-center md:text-[20px] text-[19px] font-semibold mb-2">
                    <label>개발이력</label>
                  </div>
                </div>
                <div className="flex justify-center md:text-[14px] text-[13px] text-gray-500 dark:text-gray-300">
                  <label className="text-center">
                    VALUES의 개발 이력을 보실 수 있습니다.
                  </label>
                </div>
              </div>
              {/* grid 3/4 */}
              <div className="col-span-3 grid grid-cols-3 gap-x-12 gap-y-10">
                {/* 3등분 */}
                {navBarList
                  .filter(({ menuTitle }) => menuTitle === activeTab)
                  .map(({ menuList }) => {
                    return menuList.map(({ menuName, desc, to }, index) => {
                      return (
                        <div key={index}>
                          <div className="cursor-pointer md:text-[17px] text-[16px] font-semibold pb-2 border-b-[1px] border-stone-200">
                            <Link to={to} onClick={handleHeaderClose}>
                              {menuName}
                            </Link>
                          </div>
                          <div className="py-2 md:text-[14px] text-[13px] text-gray-600 dark:text-gray-300">
                            <label>{desc}</label>
                          </div>
                        </div>
                      );
                    });
                  })}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {activeTab === "회사소개" ? (
          <div className="w-full rounded border-t-[1px] border-stroke h-[330px] z-10 text-black dark:text-white">
            <div className="grid md:grid-cols-4 container py-10 gap-16">
              {/* grid 1/4 */}
              <div className="col-span-1">
                <div className="items-center">
                  <div className="flex justify-center mb-1">
                    <img
                      src="/images/icon/valuesImage3.png"
                      alt="valuesImage"
                      className="w-[110px] rounded-full"
                    />
                  </div>
                  <div className="flex justify-center md:text-[20px] text-[19px] font-semibold mb-2">
                    <label>회사소개</label>
                  </div>
                </div>
                <div className="flex justify-center md:text-[14px] text-[13px] text-gray-500 dark:text-gray-300">
                  <label className="text-center">
                    VALUES의 기업정보를 보실 수 있습니다.
                  </label>
                </div>
              </div>
              {/* grid 3/4 */}
              <div className="col-span-3 grid grid-cols-3 gap-x-12 gap-y-10">
                {/* 3등분 */}
                {navBarList
                  .filter(({ menuTitle }) => menuTitle === activeTab)
                  .map(({ menuList }) => {
                    return menuList.map(({ menuName, desc, to }, index) => {
                      return (
                        <div key={index}>
                          <div className="cursor-pointer md:text-[17px] text-[16px] font-semibold pb-2 border-b-[1px] border-stone-200">
                            <Link to={to} onClick={handleHeaderClose}>
                              {menuName}
                            </Link>
                          </div>
                          <div className="py-2 md:text-[14px] text-[13px] text-gray-600 dark:text-gray-300">
                            <label>{desc}</label>
                          </div>
                        </div>
                      );
                    });
                  })}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/********************** PC, 패드 탭 내용 END **********************/}

        {/********************** 모바일 메뉴 토글 오픈 **********************/}
        {mobileNavigationOpen && (
          <div className="fixed !z-50 bg-white top-0 left-0 w-full h-[900px]">
            <div className="flex-col p-3">
              <div className="grid grid-cols-1 mb-7">
                <div className="flex justify-between text-[20px] font-semibold text-black border-b-[1px] border-stroke py-1.5">
                  <label className="text-[#6ba19b] font-bold">VALUES</label>
                  <button
                    onClick={() => setMobileNavigationOpen(false)}
                    className="text-black"
                  >
                    <IoMdCloseCircle size={25} />
                  </button>
                </div>
                <div className="mt-5">
                  <div className="flex justify-center">
                    <img
                      src="/images/icon/valuesImage2.png"
                      alt="valuesImage"
                      className="w-[120px] rounded-full"
                    />
                  </div>
                  <div className="flex justify-center text-gray-700 ">
                    파생상품 자동화 시스템 FIVS 구축 <br />웹 솔루션과 유지보수{" "}
                    <br />
                    기업의 성장을 이끄는 파트너
                  </div>
                </div>
              </div>
              <div className="my-2 ">
                <ul className="">
                  {navBarList.map(({ menuTitle }, index) => {
                    return (
                      <>
                        <li
                          key={index}
                          className="border-b-[1px] border-stroke py-4"
                          onClick={() => handleClickMenu(menuTitle)}
                        >
                          <div className="flex justify-between items-center font-medium">
                            <label>{menuTitle}</label>
                            {/* {menuTitle && <RiArrowDownSLine />} */}

                            {activeTab === menuTitle ? (
                              <RiArrowUpSLine size={22} />
                            ) : (
                              <RiArrowDownSLine size={22} />
                            )}
                          </div>

                          {/* <RiArrowUpWideLine /> */}
                          <div
                            className={`${
                              activeTab === menuTitle ? "block" : "hidden"
                            }`}
                          >
                            <div className="my-2 bg-stroke p-2 text-gray-700 text-[14px]">
                              {navBarList
                                .filter(
                                  ({ menuTitle }) => menuTitle === activeTab
                                )
                                .map(({ menuList }) => {
                                  return menuList.map(
                                    ({ menuName, to }, index) => {
                                      return (
                                        <Link
                                          key={index}
                                          to={to}
                                          onClick={() =>
                                            setMobileNavigationOpen(false)
                                          }
                                          className="commonBlock"
                                        >
                                          {menuName}
                                        </Link>
                                      );
                                    }
                                  );
                                })}
                            </div>
                          </div>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        )}
      </header>
      )
    </div>
  );
};
export default Header;
