import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { FiMessageCircle } from "react-icons/fi";
import WOW from "wowjs";

// 회사소개 - 기업정보
const CompanyInformation = () => {
  useEffect(() => {
    window.wow = new WOW.WOW({
      live: false,
    });

    window.wow.init({
      offset: 50,
    });
  }, []);

  const valuesDesc = [
    {
      title: "Financial Engineering",
      desc: "파생상품평가에 적합한 금융공학 모델링을 적용하며 대형 증권사 수준으로 구현",
    },

    {
      title: "Market Standard",
      desc: "FIVS 플랫폼을 통한 Valuation 대중화",
    },
    {
      title: "Web Platform",
      desc: "Web 기반 Platform으로 광범위한 서비스를 제공",
    },
    {
      title: "Creativity",
      desc: "창의성 높은 개발",
    },
  ];

  return (
    <div className="">
      {/* title */}
      <div className="mb-2 border-b-[2px] border-stroke wow fadeInUp">
        <h2 className="font-bold md:text-[30px] text-[26px] mb-2">
          IT 개발 구축 및 솔루션 기업 VALUES
        </h2>
      </div>
      <div className="my-6 mb-24 wow fadeInUp md:text-[16px] text-[15px] dark:text-gray-400">
        <div className="my-1.5">
          파생상품 자동화 시스템 FIVS 구축, 웹 솔루션과 유지보수 등 기업의
          성장을 이끄는 파트너가 되어 드립니다.
        </div>
      </div>

      {/* semi - title */}
      <div className="wow fadeInUp mb-4">
        <h2 className="font-bold md:text-[28px] text-[24px]">VALUES 현황 </h2>
      </div>

      {/* desciption */}
      <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mb-24 wow fadeInLeft">
        <div className="h-auto">
          <table className="w-full">
            <tbody className="py-2 border-y-[1px] border-gray-300 dark:border-gray-300 w-full md:text-[16px] text-[14px] ">
              <tr className="py-5 w-full border-b-[1px] border-stroke dark:border-gray-500">
                <th className="text-left pl-5 py-5 font-medium w-2/5">
                  회사명
                </th>
                <td className="font-normal ">VALUES (주식회사 밸류즈)</td>
              </tr>
              <tr className="py-5 w-full border-b-[1px] border-stroke dark:border-gray-500">
                <th className="text-left pl-5 py-5 font-medium w-1/5">
                  대표이사
                </th>
                <td className="font-normal ">조민수</td>
              </tr>
              <tr className="py-5 w-full border-b-[1px] border-stroke dark:border-gray-500">
                <th className="text-left pl-5 py-5 font-medium w-1/5">
                  설립일자
                </th>
                <td className="font-normal ">2021년 05월 22일</td>
              </tr>
              <tr className="py-5 w-full border-b-[1px] border-stroke dark:border-gray-500">
                <th className="text-left pl-5 py-5 font-medium w-1/5">
                  회사 이메일
                </th>
                <td className="font-normal ">info@the-value.co.kr</td>
              </tr>
              <tr className="py-5 w-full border-b-[1px] border-stroke dark:border-gray-500">
                <th className="text-left pl-5 py-5 font-medium w-1/5">
                  파생상품 평가문의
                </th>
                <td className="font-normal ">010-5066-9004</td>
              </tr>
              <tr className="py-5 w-full border-b-[1px] border-stroke dark:border-gray-500">
                <th className="text-left pl-5 py-5 font-medium w-1/5">
                  시스템 문의
                </th>
                <td className="font-normal ">dev@the-value.co.kr</td>
              </tr>
              <tr className="py-5 w-full  dark:border-gray-500">
                <th className="text-left pl-5 py-5 font-medium w-1/5">
                  솔루션 개발 및 유지보수 문의
                </th>
                <td className="font-normal ">010-4313-0422</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="md:block hidden wow fadeInUp">
          <img
            src="/images/about/valuesCareer.png"
            alt="About"
            className="dark:hidden w-4/5"
          />
          <img
            src="/images/about/valuesCareer2.png"
            alt="About"
            className="hidden dark:block w-4/5"
          />
        </div>
      </div>

      <div className="md:w-11/12 w-full md:mt-24 md:mb-24 mt-16 mb-7 ">
        <div className=" wow fadeInUp">
          <h2 className="font-bold md:text-[28px] text-[24px]">
            {" "}
            VALUES 핵심 기술
          </h2>
        </div>
        <div className="px-1 md:block hidden wow fadeInUp">
          <img
            src="/images/about/valuesInstroduce.png"
            alt="valuesInstroduce"
            className="w-full dark:hidden"
          />
          <img
            src="/images/about/valuesInstroduce2.png"
            alt="valuesInstroduce"
            className="w-full hidden dark:block"
          />
        </div>
      </div>
      {/* 모바일용 */}
      <div className="wow fadeInUp md:hidden block pb-20">
        <div className="mx-auto h-auto ">
          <Swiper
            style={{
              "--swiper-pagination-color": "#8fb1e1",
              "--swiper-navigation-color": "#98cae9",
            }}
            className="opacity-100 pb-20"
            modules={[Pagination, Autoplay]}
            loop={true}
            spaceBetween={50}
            centeredSlides={false}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            autoHeight={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 3,
              },
            }}
            // pagination={{
            //   clickable: true,
            // }}
            // scrollbar={{ draggable: true }}
            // autoHeight="true"
          >
            {valuesDesc.map(({ title, desc }, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="border-[1px] border-gray-200  bg-white dark:bg-dark rounded-lg shadow-md p-8 h-48 ">
                    {/* <div className="mb-4 flex justify-between border-b border-stroke dark:border-strokedark pb-6 mb-7.5"> */}
                    <div className="flex justify-between border-b-[1px]  border-[#61a399] dark:border-strokedark pb-2 mb-3.5">
                      <div className="">
                        <h4 className="text-black dark:text-white mb-1.5 font-bold ">
                          # 0{index + 1}
                        </h4>
                        <p className="font-semibold"> {title}</p>
                      </div>
                      {/* <img
                      className=""
                      src="./images/user/user-01.svg"
                      alt="User"
                    /> */}
                    </div>
                    <p className="h-20 dark:text-gray-400">
                      {desc ? " - " + desc : ""}
                    </p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default CompanyInformation;
