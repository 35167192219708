import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

//partners List START
const partners = [
  { src: "/images/logo/wooribank.png", alt: "wooribank" },
  { src: "/images/logo/samil.png", alt: "samil" },
  { src: "/images/logo/anse.png", alt: "anse" },
  { src: "/images/logo/removebg-chang.png", alt: "chang" },
  { src: "/images/logo/beyul.png", alt: "beyul" },
  { src: "/images/logo/solbon.png", alt: "solbon" },
  { src: "/images/logo/lodin.png", alt: "lodin" },
  { src: "/images/logo/juro.png", alt: "juro_logo" },
  { src: "/images/logo/donga.png", alt: "donga" },
  { src: "/images/logo/woori_logo.png", alt: "woori_logo" },
  { src: "/images/logo/han-removebg.png", alt: "hansu" },
  { src: "/images/logo/bomyung.png", alt: "bomyung" },
];
//partners List END
const Partners = () => {
  return (
    <section className="md:my-14 my-1 bg-alabaster dark:bg-stroke border border-x-0 border-y-stroke dark:border-y-strokedark py-4">
      <div className="mx-auto max-w-c-1390 3xl:px-36 2xl:px-28 md:px-24 px-4">
        <Swiper
          className="partners-swiper grid grid-cols-3 md:grid-cols-6 gap-7.5 lg:gap-12.5 xl:gap-29 justify-center items-center "
          // style={{
          //   "--swiper-pagination-color": "#8fb1e1",
          //   "--swiper-navigation-color": "#98cae9",
          // }}
          modules={[Navigation, Pagination, Scrollbar, Autoplay]}
          spaceBetween={50}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 5,
              spaceBetween: 40,
            },
            1200: {
              slidesPerView: 6,
              spaceBetween: 40,
            },
          }}
          // pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          // autoHeight="true"
        >
          <div className="">
            {partners.map(({ src, alt }, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className="md:h-12  partners-swiper-slide"
                >
                  <div className="flex !items-center justify-center">
                    <img
                      className="object-cover 2xl:w-8/12 md:w-9/12 w-10/12"
                      src={src}
                      alt={alt}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </div>
        </Swiper>
      </div>
    </section>
  );
};

export default Partners;
