import React, { useEffect, useRef, useState } from "react";
import RevealOnScroll from "./RevealOnScroll";

// values가 하는일들
const Cta = () => {
  const salesItems = [
    {
      id: 1,
      imageName: "sales1.png",
    },
    {
      id: 2,
      imageName: "sales2.png",
    },
    {
      id: 3,
      imageName: "sales3.png",
    },
    {
      id: 4,
      imageName: "sales4.png",
    },
  ];

  const valuesWorksList = [
    {
      idx: "01",
      title: "파생상품평가 자동화 개발",
      desc: "평가부터 보고서까지 one-stop으로 사용자가 손쉽게 평가할 수 있는 툴을 제공합니다.",
    },
    {
      idx: "02",
      title: "MRC 내부통제 자동화 개발",
      desc: "MRC 시스템은 정확하고 정교한 Pricing과 회계처리를 쉽게 관리 할 수 있습니다.",
    },
    {
      idx: "03",
      title: "반응형 웹 개발",
      desc: "PC, 태블릿, 모바일에 맞는 UI와 고객의 요구에 맞는 시스템 개발을 하고 있습니다.",
    },
    {
      idx: "04",
      title: "유지보수 관리",
      desc: "VALUES는 다양한 파트너 고객사들의 프로그램을 유지보수 중에 있습니다.",
    },
  ];
  return (
    <section id="observe" className="relative pt-[150px]">
      <div className="container lg:max-w-[1120px]">
        <div>
          <div className="-mx-4 flex flex-wrap justify-between">
            <div className="w-full px-4 md:w-1/2 ">
              <div
                className="wow fadeInLeft lg:ml-auto lg:max-w-[510px] sticky top-28"
                data-wow-delay=".3s"
              >
                <div>
                  <span className="mb-4 block text-lg font-medium text-primary md:text-[22px]">
                    IT 개발 구축
                  </span>
                  <h2 className="mb-8 text-3xl font-bold text-black dark:text-white sm:text-4xl md:text-[44px] md:leading-tight">
                    VALUES
                  </h2>
                  {/* <p className="mb-[30px] text-base leading-relaxed text-body">
                    4가지 기술력을 소개합니다.
                  </p> */}

                  {valuesWorksList.map(({ idx, title, desc }) => {
                    return (
                      <div key={idx} className="mb-[30px] flex items-center">
                        <div className="mr-[22px] flex h-[60px] w-[60px] items-center justify-center rounded-full border border-stroke text-xl font-semibold text-black dark:border-stroke-dark dark:bg-dark dark:text-white">
                          {idx}
                        </div>
                        <div>
                          <h5 className="md:text-[17px] text-[16px] font-medium text-black dark:text-white">
                            {title}
                          </h5>
                          <p className="md:text-[15px] text-[14px]  text-body">
                            {desc}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="w-full px-4 md:w-1/2 md:block hidden">
              {salesItems.map((item, index) => {
                return <RevealOnScroll key={item.id} item={item} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
