import React from "react";

const Footer = () => {
  return (
    <div>
      <footer>
        <div className="bg-[#F8FAFB] pt-[40px] pb-[30px] dark:bg-[#15182A]">
          <div className="container max-w-[1390px]">
            <div className="-mx-4 flex flex-wrap">
              <div className="w-full px-4 grid md:grid-cols-4 grid-cols-1 gap-5 items-center">
                <div className=" max-w-[300px] ">
                  <a href="/" className=" inline-block">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo/values_white.png`}
                      alt="logo"
                      className="block max-w-full dark:hidden w-3/5"
                    />
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo/values_black.png`}
                      alt="logo"
                      className="hidden max-w-full dark:block w-3/5"
                    />
                  </a>
                </div>
                <div>
                  <p className="text-base text-body">
                    대표메일 : info@the-value.co.kr
                  </p>
                </div>
                <div>
                  <p className="text-base text-body">
                    시스템 문의 : dev@the-value.co.kr
                  </p>
                </div>
                <div>
                  <p className="text-base text-body">
                    파생상품 평가문의 : 010-5066-9004
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" bg-[#68afa5] py-7 dark:bg-black">
          <div className="container max-w-[1390px]">
            <div className="-mx-3 flex flex-wrap">
              <div className="order-last w-full px-3 lg:order-first lg:w-1/3">
                <p className="mt-4 text-center text-base text-white lg:mt-0 lg:text-left">
                  &copy; 2024 VALUES. All rights reserved
                </p>
              </div>

              <div className="w-full px-3 md:w-1/2 lg:w-1/3 hidden md:block">
                &nbsp;
              </div>

              <div className="w-full px-3 md:w-1/2 lg:w-1/3">
                <div className="flex items-center justify-center space-x-4 sm:space-x-8 md:justify-end lg:justify-end">
                  <a
                    href="/register/termsofuse"
                    target="_blank"
                    className="text-base text-white"
                  >
                    Privacy Policy
                  </a>
                  <a
                    href="/register/termsofuse"
                    target="_blank"
                    className="text-base text-white"
                  >
                    Terms and conditions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
