import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { FiMessageCircle } from "react-icons/fi";
import WOW from "wowjs";
import { IoArrowDownSharp } from "react-icons/io5";
import { FcPortraitMode } from "react-icons/fc";

// IT 개발 솔루션 - 웹 개발과 유지보수
const WebSolution = () => {
  const [detailDesc, setDetailDesc] = useState("0");

  useEffect(() => {
    window.wow = new WOW.WOW({
      live: false,
    });

    window.wow.init({
      offset: 50,
    });
  }, [detailDesc]);

  const webDevelopment = [
    {
      idx: "1",
      title: "GIS 기반 좌표계산 웹 시스템",
      desc: "좌표 데이터를 통한 데이터 산출 및 지도 시각화 \n반응형 지원(모바일, 웹, 태블릿) \n 회원가입 및 로그인 \n  관리자 및 권한 분리 \n 관리자 페이지 구성 \n 파일 및 이미지 업로드, 다운로드 \n 그리드 API 연동 및 데이터 입출력 \n 대시보드 형태의 관리자 페이지 구성",
      desc2: "React / TailwindCss",
      desc3: "Spring Boot / MariaDB",
      imageSrc: "/images/websolution/landmaps.png",
    },

    {
      idx: "2",
      title: "파생상품평가 자동화 시스템",
      desc: "VALUES 대표 솔루션 \n 매자닌 상품(RCPS, CB, SO) 및 IRS, CRS, 통화선도 등의 파생상품을 평가 \n 평가부터 보고서까지 one-stop으로 사용자가 손쉽게 평가할 수 있는 툴 제공 \n 내부통제(MRC) 자동화 툴 제공",
      desc2: "React / TypeScript / TailwindCss",
      desc3: "Spring Boot / MariaDB",
      imageSrc: "/images/websolution/fivsWebSolution.png",
    },
    {
      idx: "3",
      title: "외국인 대상 체험 신청 웹",
      desc: "국내 체험 상품을 외국인 대상으로 소개하고 결제를 통한 체험 예약 \n 반응형 지원(모바일, 웹, 태블릿) \n 회원가입 및 로그인 \n  관리자 및 권한 분리 \n 관리자 페이지 구성 \n 파일 및 이미지 업로드, 다운로드 \n 그리드 API 연동 및 데이터 입출력 \n 모바일 UI \n 대시보드 형태의 관리자 페이지 구성",
      desc2: "React / TailwindCss",
      desc3: "Spring Boot / MariaDB",
      imageSrc: "/images/websolution/gluck.png",
    },
    {
      idx: "4",
      title: "선행기술 신청 및 현황 관리 시스템",
      desc: "회원가입 및 로그인 \n  관리자 및 권한 분리 \n 관리자 페이지 구성 \n 파일 및 이미지 업로드, 다운로드 \n 그리드 API 연동 및 데이터 입출력 \n 대시보드 형태의 관리자 페이지 구성",
      desc2: "jQuery / JavaScript",
      desc3: "Spring Framework / MariaDB",
      imageSrc: "/images/websolution/ipex.png",
    },
    {
      idx: "5",
      title: "수력 에너지 통계 데이터 산출 웹",
      desc: "하드웨어 연동을 통한 데이터 누적 및 통계 데이터 산출 \n 통계 데이터 차트 시각화 \n반응형 지원(모바일, 웹, 태블릿) \n 회원가입 및 로그인 \n  관리자 및 권한 분리 \n 관리자 페이지 구성 \n 파일 및 이미지 업로드, 다운로드 \n 대시보드 형태의 관리자 페이지 구성",
      desc2: "React / TailwindCss",
      desc3: "Spring Boot / MariaDB",
      imageSrc: "/images/websolution/monitor.png",
    },
    {
      idx: "6",
      title: "대학원 주소록 관리 시스템",
      desc: "대학원 기수별 주소록 관리 \n 반응형 지원(모바일, 웹, 태블릿) \n 관리자 및 권한 분리 \n 관리자 페이지 구성 \n 파일 및 이미지 업로드, 다운로드 \n 그리드 API 연동 및 데이터 입출력 \n 대시보드 형태의 관리자 페이지 구성",
      desc2: "Thymeleaf / JavaScript",
      desc3: "Spring Boot / MariaDB",
      imageSrc: "/images/websolution/seoul.png",
    },
  ];

  // 이미지 클릭시 상세보기 이벤트 실행
  const handleButtonClick = (idx) => {
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤
    setDetailDesc(idx);
  };

  // 상세보기 닫기
  const handleCloseToggle = () => {
    setDetailDesc("0");
  };
  return (
    <div className="">
      {/* title */}
      <div className="wow fadeInUp mb-2 border-b-[2px] border-stroke ">
        <h2 className=" font-bold md:text-[30px] text-[26px] mb-2">
          웹 개발과 유지보수
        </h2>
      </div>
      <div className="wow fadeInUp my-2 mb-20 md:text-[16px] text-[15px] dark:text-gray-400">
        <div className="my-1.5">
          VALUES의 개발팀은 최신 기술과 효율적인 개발 방식으로 최적화된 웹
          환경을 구축하며, 지속적인 관리로 성능과 보안을 강화해 언제나 안심할 수
          있는 웹 서비스를 제공합니다.
        </div>
        <div className="my-1.5">
          데스크탑, 태블릿, 모바일 등 다양한 디바이스에서도 동일하게 편리한
          사용감을 제공합니다.
        </div>
        <div className="my-1.5">
          안정적이고 확장 가능한 웹 개발과 체계적인 유지보수를 통해 귀사의
          온라인 비즈니스 성공을 돕겠습니다.
        </div>
      </div>

      <div className="wow fadeInUp mt-10 font-medium md:text-[16px] text-[14px] flex justify-start items-center ">
        <FcPortraitMode className="inline mr-2" size="34" /> 이미지를 클릭하시면
        내용을 상세하게 보실 수 있습니다.
      </div>
      {/* <div className={`${detailDesc !== "0" ? "hidden" : "block"}`}> */}
      <div className="my-10">
        <div className="mx-auto !h-auto wow fadeInUp">
          <Swiper
            style={{
              "--swiper-pagination-color": "#8fb1e1",
              "--swiper-navigation-color": "#98cae9",
            }}
            className="opacity-100 "
            modules={[Pagination, Autoplay]}
            loop={true}
            spaceBetween={50}
            centeredSlides={false}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            autoHeight={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 3,
              },
            }}
            // pagination={{
            //   clickable: true,
            // }}
            // scrollbar={{ draggable: true }}
            // autoHeight="true"
          >
            {webDevelopment.map(({ idx, title, imageSrc }, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="h-[380px] border-[1px] border-stone-200 shadow-lg  dark:bg-dark p-2.5 rounded-lg">
                    <div className="md:text-[18px] text-[17px] font-medium pl-2 py-2 ">
                      {title}
                    </div>
                    <button
                      onClick={() => handleButtonClick(idx)}
                      className="w-full flex justify-center"
                    >
                      <img
                        src={imageSrc}
                        alt="imageSrc"
                        className="py-2 imageSize h-[300px] "
                      />
                    </button>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        {/****** Detail START ******/}
        {detailDesc !== "0" && (
          <div className="">
            <div className="zindexStyle fixed bg-[#eaecf0] dark:bg-[#eaecf0] top-0 !z-9999 left-0 w-full h-full">
              <div className="wow fadeInLeft" data-wow-delay=".3s">
                <div className="p-10 ">
                  <div className="flex justify-between pb-2 border-b-[1px] border-stone-300">
                    {/* title */}
                    <div className="py-1.5 font-medium text-black md:text-[18px] text-[16px]">
                      웹 개발 / 유지보수 상세보기
                    </div>
                    {/* 닫기버튼 */}
                    <button
                      onClick={handleCloseToggle}
                      className="bg-[#393f49] dark:bg-[#212338] text-white py-2 px-3.5  md:text-[11px] text-[10px]"
                    >
                      X
                    </button>
                  </div>
                </div>
                {/* 설명 */}

                <div className="grid md:grid-cols-2 grid-cols-1 md:gap-5 h-full">
                  {webDevelopment
                    .filter(({ idx }) => idx === detailDesc)
                    .map(({ title, imageSrc, desc, desc2, desc3 }, index) => {
                      return (
                        <>
                          <div className="flex justify-center border-r-[1px] border-stone-50 w-full md:h-[500px] h-[270px] md:p-10 px-10 md:py-2 py-0.5">
                            <img
                              src={imageSrc}
                              alt="imageSrc"
                              className="py-1 "
                            />
                          </div>
                          <div
                            className="md:p-2 px-11"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            <div key={index} className="text-black mt-7">
                              <div className="md:text-[20px] text-[17px] font-medium md:mt-5 md:mb-7 mb-5 md:border-b-[0px] border-b-[1px] border-gray-300 pb-2">
                                {title}
                              </div>
                              <div className="font-normal text-gray-700 md:text-[16px] text-[15px] md:mb-10 mb-5">
                                {desc}
                              </div>

                              <div className="text-gray-700 md:mb-10 mb-5">
                                <p className="mb-1.5 font-medium md:text-[18px] text-[16px]">
                                  FrontEnd
                                </p>
                                <label className="font-normal md:text-[17px] text-[15px]">
                                  {desc2}
                                </label>
                              </div>
                              <div className=" text-gray-700  md:mb-10 mb-5">
                                <p className="mb-1.5 font-medium md:text-[18px] text-[16px]">
                                  BackEnd
                                </p>
                                <label className="font-normal md:text-[17px] text-[15px]">
                                  {desc3}
                                </label>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* detail END  */}
      </div>
    </div>
  );
};

export default WebSolution;
