import React from "react";
import { FcGenealogy } from "react-icons/fc";
import { FcExternal } from "react-icons/fc";
import { FcMindMap } from "react-icons/fc";
import { FcFinePrint } from "react-icons/fc";
import { HiArrowPath } from "react-icons/hi2";
import { FcCollaboration } from "react-icons/fc";

// values의 6가지 기능 소개
const Features = () => {
  return (
    <section id="features" className="relative z-10 pt-[110px]">
      <div className="container">
        <div
          className="wow fadeInUp mx-auto mb-14 max-w-[690px] text-center lg:mb-[70px]"
          data-wow-delay=".2s"
        >
          <h2 className="mb-4 text-3xl font-bold text-black dark:text-white sm:text-4xl md:text-[44px] md:leading-tight">
            아이디어를 현실로, <br />
            맞춤형 기술 솔루션
          </h2>
          <p className="text-base text-body">
            다양한 고객의 요구를 반영하여 최적화된 기술을 개발합니다.
          </p>
        </div>
      </div>

      <div className="container max-w-[1390px]">
        <div className="rounded-2xl bg-white px-5 pt-14 pb-14 shadow-card dark:bg-dark dark:shadow-card-dark md:pb-1 lg:pt-20 lg:pb-5 xl:px-10">
          <div className="-mx-4  flex-wrap grid md:grid-cols-3 grid-cols-1">
            <div className="w-full px-4">
              <div
                className="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                data-wow-delay=".2s"
              >
                <div className="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl ">
                  <FcMindMap size={47} />
                </div>
                <h3 className="mb-4 text-xl font-semibold text-black dark:text-white sm:text-[22px] xl:text-[26px]">
                  반응형 UI
                </h3>
                <p className="text-base text-body">
                  데스크탑, 태블릿, 모바일 등 다양한 디바이스에서도 동일하게
                  편리한 사용감을 보장합니다.
                </p>
              </div>
            </div>

            <div className="w-full px-4 ">
              <div
                className="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                data-wow-delay=".3s"
              >
                <div className="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl ">
                  <FcExternal size={47} />
                </div>
                <h3 className="mb-4 text-xl font-semibold text-black dark:text-white sm:text-[22px] xl:text-[26px]">
                  목표에 맞춘 전략적 웹 기획
                </h3>
                <p className="text-base text-body">
                  비즈니스 목표에 맞춘 전략적인 웹 기획을 통해, 단순한
                  웹사이트가 아닌 성장을 촉진하는 디지털 솔루션을 제공합니다.
                </p>
              </div>
            </div>

            <div className="w-full px-4 ">
              <div
                className="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                data-wow-delay=".2s"
              >
                <div className="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl ">
                  <FcGenealogy size={47} />
                </div>
                <h3 className="mb-4 text-xl font-semibold text-black dark:text-white sm:text-[22px] xl:text-[26px]">
                  축적된 경험
                </h3>
                <p className="text-base text-body">
                  다양한 프로젝트에서 축적한 노하우와 깊이 있는 기술력으로,
                  최적화된 웹 개발을 구현합니다.
                </p>
              </div>
            </div>

            <div className="w-full px-4 ">
              <div
                className="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                data-wow-delay=".2s"
              >
                <div className="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl ">
                  <FcFinePrint size={47} />
                </div>
                <h3 className="mb-4 text-xl font-semibold text-black dark:text-white sm:text-[22px] xl:text-[26px]">
                  VALUES만의 독자적인 솔루션, 차별화된 경쟁력
                </h3>
                <p className="text-base text-body">
                  다년간의 경험과 기술을 바탕으로 완성된 자체 솔루션으로 고객의
                  성공적인 비즈니스를 보장합니다.
                </p>
              </div>
            </div>

            <div className="w-full px-4 ">
              <div
                className="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                data-wow-delay=".3s"
              >
                <div className="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl ">
                  <FcCollaboration size={47} />
                </div>
                <h3 className="mb-4 text-xl font-semibold text-black dark:text-white sm:text-[22px] xl:text-[26px]">
                  풍부한 사용자 UI 제공
                </h3>
                <p className="text-base text-body">
                  사용자의 니즈에 충족하는 사용자 UI를 제공 및 커스터마이징을
                  제공합니다.
                </p>
              </div>
            </div>

            <div className="w-full px-4 ">
              <div
                className="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                data-wow-delay=".4s"
              >
                <div className="mx-auto mb-8 flex h-[90px] w-[90px] items-center justify-center rounded-3xl ">
                  <HiArrowPath size={47} color="green" />
                </div>
                <h3 className="mb-4 text-xl font-semibold text-black dark:text-white sm:text-[22px] xl:text-[26px]">
                  지속적인 업데이트
                </h3>
                <p className="text-base text-body">
                  VALUES만의 자체 솔루션(FIVS)은 지속적인 업데이트를 통해 더욱
                  더 빠르고, 정확한 결과를 산출합니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
