import React, { useState } from "react";
import { FaFaceGrinStars } from "react-icons/fa6";
import { FaRegFaceLaughSquint } from "react-icons/fa6";
import { FaRegFaceGrinSquint } from "react-icons/fa6";
import { FaRegFaceGrin } from "react-icons/fa6";
//FIVS FQA List
const faqList = [
  {
    title: "FIVS 서비스 문의",
    desc: "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities, digital experiences.",
  },
  {
    title: "FIVS 회원탈퇴 문의",
    desc: "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities, digital experiences.",
  },
  {
    title: "FIVS 회원가입 문의",
    desc: "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities, digital experiences.",
  },
];

// 클라이언트 사용 후기
const Testimonials = () => {
  //Faq selected value
  const [selected, setSelected] = useState(null);
  //Faq toggleMethod
  const faqToggleMethod = (index) => {
    // console.log(index);

    // toggle start
    if (selected !== index) {
      setSelected(index);
    } else {
      setSelected(null);
    }
  };
  return (
    <section id="testimonials" className="relative z-10 pt-[130px] pb-[60px]">
      <div className="container">
        <div
          className="wow fadeInUp mx-auto mb-14 max-w-[690px] text-center lg:mb-[70px]"
          data-wow-delay=".2s"
        >
          <h2 className="text-3xl font-bold mb-5 text-black dark:text-white sm:text-4xl md:text-[44px] md:leading-tight">
            클라이언트 후기
          </h2>
          {/* <h4 classNameName="mb-4">What Client's Say</h4> */}
          <p className="text-base text-body">
            VALUES의 웹 개발 및 유지보수, FIVS 시스템에 대한 후기 입니다.
          </p>
        </div>
      </div>

      {/* 1번째 후기 */}
      <div className="container overflow-hidden lg:max-w-[1160px]">
        <div className="-mx-6 flex flex-wrap">
          <div className="w-full px-6 lg:w-1/2">
            <div
              className="border-[1px] wow fadeInUp mb-[50px] rounded-lg bg-white py-9 px-7 shadow-card dark:bg-dark dark:shadow-card-dark sm:px-9 lg:px-7 xl:px-9"
              data-wow-delay=".2s"
            >
              <div className="mb-5 border-b border-stroke dark:border-stroke-dark">
                <p className="pb-9 text-base text-body">
                  2년전 저희 회사 홈페이지를 업데이트 하고 싶어서 의뢰했었어요.
                  친절하게 상담해 주셨고, 벌써 2번째 계약하고 지금은 월단위로
                  가성비 있게 유지보수만 하고 있어요!!{" "}
                </p>
              </div>

              <div className="items-center justify-between sm:flex lg:block xl:flex">
                <div className="mb-4 flex items-center sm:mb-0 lg:mb-4 xl:mb-0">
                  <div className="mr-4 h-[56px] w-full max-w-[56px] rounded-full flex items-center">
                    <img
                      src="images/review/review1.png"
                      alt="review1"
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <div>
                    <h5 className="text-base font-medium text-black dark:text-white">
                      "djc7712"님의 후기
                    </h5>
                    {/* <p className="text-sm text-body">개발자</p> */}
                  </div>
                </div>

                <div className="flex items-center space-x-3 sm:justify-end lg:justify-start xl:justify-end">
                  <p className="text-base font-medium text-black dark:text-white">
                    5.0
                  </p>
                  <div className="flex items-center space-x-[6px]">
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 2번째 후기 */}
          <div className="w-full px-6 lg:w-1/2">
            <div
              className="border-[1px]  wow fadeInUp mb-[50px] rounded-lg bg-white py-9 px-7 shadow-card dark:bg-dark dark:shadow-card-dark sm:px-9 lg:px-7 xl:px-9"
              data-wow-delay=".3s"
            >
              <div className="mb-5 border-b border-stroke dark:border-stroke-dark">
                <p className="pb-9 text-base text-body">
                  파생상품평가 관련하여 사용 중에 있습니다. 지원하는 자동화 툴은
                  값만 입력하면 편하고 빠르게 결과를 도출할 수 있어 너무
                  편합니다. 재이용 할 마음 있습니다.
                </p>
              </div>

              <div className="items-center justify-between sm:flex lg:block xl:flex">
                <div className="mb-4 flex items-center sm:mb-0 lg:mb-4 xl:mb-0">
                  <div className="mr-4 h-[56px] w-full max-w-[56px] rounded-full flex items-center">
                    <img
                      src="images/review/review4.png"
                      alt="author"
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <div>
                    <h5 className="text-base font-medium text-black dark:text-white">
                      "accountant87tj"님의 후기
                    </h5>
                    {/* <p className="text-sm text-body">Product Designer</p> */}
                  </div>
                </div>

                <div className="flex items-center space-x-3 sm:justify-end lg:justify-start xl:justify-end">
                  <p className="text-base font-medium text-black dark:text-white">
                    5.0
                  </p>
                  <div className="flex items-center space-x-[6px]">
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 3번째 후기 */}
          <div className="w-full px-6 lg:w-1/2">
            <div
              className="border-[1px] wow fadeInUp mb-[50px] rounded-lg bg-white py-9 px-7 shadow-card dark:bg-dark dark:shadow-card-dark sm:px-9 lg:px-7 xl:px-9"
              data-wow-delay=".2s"
            >
              <div className="mb-5 border-b border-stroke dark:border-stroke-dark">
                <p className="pb-9 text-base text-body">
                  제가 이용하는 홈페이지 리뉴얼 하고 후기를 올린다길래 저도
                  동참했어용 ㅋㅋ 파이브즈 rcps 계산 시스템이 좋아서 내부통제도
                  사용하고 싶네요.. 업무하는 속도가 현저하게 빨라졌어요.. 만들어
                  주셔서 감사해요ㅋㅋ
                </p>
              </div>

              <div className="items-center justify-between sm:flex lg:block xl:flex">
                <div className="mb-4 flex items-center sm:mb-0 lg:mb-4 xl:mb-0">
                  <div className="mr-4 h-[56px] w-full max-w-[56px] rounded-full flex items-center">
                    <img
                      src="images/review/review3.png"
                      alt="author"
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <div>
                    <h5 className="text-base font-medium text-black dark:text-white">
                      "elarajei"님의 후기
                    </h5>
                    {/* <p className="text-sm text-body">App Developer</p> */}
                  </div>
                </div>

                <div className="flex items-center space-x-3 sm:justify-end lg:justify-start xl:justify-end">
                  <p className="text-base font-medium text-black dark:text-white">
                    5.0
                  </p>
                  <div className="flex items-center space-x-[6px]">
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 4번째 후기 */}
          <div className="w-full px-6 lg:w-1/2">
            <div
              className="border-[1px] wow fadeInUp mb-[50px] rounded-lg bg-white py-9 px-7 shadow-card dark:bg-dark dark:shadow-card-dark sm:px-9 lg:px-7 xl:px-9"
              data-wow-delay=".3s"
            >
              <div className="mb-5 border-b border-stroke dark:border-stroke-dark">
                <p className="pb-9 text-base text-body">
                  여기 찐입니다. 말이 필요없습니다. 여기처럼 친절하고 잘
                  만들어주는 곳 없었어요. 위시켓에서도 여기 대표님 평가 후기
                  있는데 괜히 상위 0.1프로가 아닙니다. 웹 프로젝트는 앞으로
                  이곳에서만 이용할 겁니다.
                </p>
              </div>

              <div className="items-center justify-between sm:flex lg:block xl:flex">
                <div className="mb-4 flex items-center sm:mb-0 lg:mb-4 xl:mb-0">
                  <div className="mr-4 h-[56px] w-full max-w-[56px] rounded-full flex items-center">
                    <img
                      src="images/review/review2.png"
                      alt="author"
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <div>
                    <h5 className="text-base font-medium text-black dark:text-white">
                      "mancityparan"님의 후기
                    </h5>
                    {/* <p className="text-sm text-body">Seo Expert</p> */}
                  </div>
                </div>

                <div className="flex items-center space-x-3 sm:justify-end lg:justify-start xl:justify-end">
                  <p className="text-base font-medium text-black dark:text-white">
                    5.0
                  </p>
                  <div className="flex items-center space-x-[6px]">
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49_480)">
                          <path
                            d="M10 15.2171L4.1225 18.5071L5.435 11.9004L0.489166 7.32712L7.17833 6.53378L10 0.417114L12.8217 6.53378L19.5108 7.32712L14.565 11.9004L15.8775 18.5071L10 15.2171Z"
                            fill="#EABF23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_480">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
