import React, { useEffect } from "react";
import WOW from "wowjs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { IoIosArrowRoundForward } from "react-icons/io";
// VALUATION 자동화 - MRC (내부통제)
const MrcAuto = () => {
  useEffect(() => {
    window.wow = new WOW.WOW({
      live: false,
    });

    window.wow.init({
      offset: 50,
    });
  }, []);

  // mrcWork
  const mrcWork = [
    {
      title: "Overview",
      desc: "MRC 메인화면은 대시보드 형태로 구성되어 있으며, 현재 작업중인 MRC 진행상황, MRC 히스토리, 요청사항 등 전반적인 개관(Overview)을 보여주는 화면입니다.",
    },
    {
      title: "기초설정",
      desc: "MRC 작업을 수행하기위한 사전작업으로, 검증건수, 외부평가자, 상급자 정보를 사전에 확정하는 단계입니다.",
    },
    {
      title: "전문가 질의서 송부",
      desc: "외부 평가기관의 적격성 검증은 외부 평가자의 평가업무 수행능력 및 독립성 여부를 확인하기 위한 절차입니다. 전문가 질의서 링크를 메일로 송부합니다.",
    },
    {
      title: "평가검증 및 회계처리",
      desc: "검토대상 한건씩 검증을 수행합니다. 평가검증이 끝난 후 회계처리의 정합성에 대해서 검증을 수행합니다.",
    },
    {
      title: "상급자 상신",
      desc: "상급자 상신에서는 MRC 진행 현황에 대해서 보여주며, 질의서 및 계산검증이 모두 완료되었을 경우 결재 상신을 수행합니다. 결재는 순서대로 진행되며, 완료 시 담당자에게 메일로 알림이 송부됩니다.",
    },
    {
      title: "MRC문서 생성",
      desc: "상급자 결재까지 완료 후 담당자는 MRC문서를 생성 할 수 있습니다. 필요시 상급자가 확인했던 정보를 다시한번 확인할 수 있습니다.",
    },
  ];

  const mrcDesc = [
    {
      title: "평가대상 집계의 완전성(실무자 수기 작업)",
      desc: "",
    },

    {
      title: "MRC 작업 수행 (실무자 FIVS 이용)",
      desc: "외부평가자 적정성 검토(Link 발송)",
    },
    {
      title: "MRC 작업 수행 (실무자 FIVS 이용)",
      desc: "공정가치평가 계산 및 차이검토",
    },
    {
      title: "MRC 작업 수행 (실무자 FIVS 이용)",
      desc: "공정가치평가 전표반영 확인",
    },
    {
      title: "MRC 작업 수행 (실무자 FIVS 이용)",
      desc: "상급자 리뷰 및 결재(Link 발송)",
    },
    {
      title: "MRC 작업 수행 (실무자 FIVS 이용)",
      desc: "MRC 작업문서 다운로드",
    },
    {
      title: "MRC 문서 추가 보완 (실무자 수기 작업)",
      desc: "",
    },
  ];

  return (
    <div className="">
      {/* title */}
      <div className="mb-2 border-b-[2px] border-stroke wow fadeInUp">
        <h2 className="font-bold md:text-[30px] text-[26px] mb-2">
          내부통제(MRC)
        </h2>
      </div>

      <div className="my-4 mb-20 wow fadeInUp">
        <div className=" md:text-[16px] text-[15px]">
          경영진검토통제(MRC)는 회계처리 등 통제 위험이 높은 거래나,
          거래수준에서 운영되는 통제 활동의 결과를 모니터링 하는 것을
          의미합니다. <br /> 이는 경영진 등 상위 관리자에 의해 수행되는 리뷰
          속성을 가진 통제입니다.
        </div>
      </div>

      {/* semi - title */}
      <div className="wow fadeInUp">
        <h2 className="font-bold md:text-[28px] text-[24px]">
          MRC 프로세스 요건 정의
        </h2>
      </div>
      <div className="my-4 wow fadeInUp">
        <div className="md:text-[16px] text-[15px]">
          MRC 프로세스는 총 4가지의 요건으로 구성 되어있으며, FIVS는 MRC
          프로세스의 전반적인 작업을 지원합니다.
        </div>
      </div>
      {/* desciption */}
      <div className="flex justify-center my-10 wow fadeInUp ">
        {/* pc */}
        <div className="wow fadeInUp md:block hidden ">
          <img
            src="/images/about/mrcprocess.png "
            alt="About"
            className="dark:hidden block w-full"
          />
          <img
            src="/images/about/mrcprocess2.png"
            alt="About"
            className="hidden dark:block w-full"
          />
        </div>
        {/* mobile */}
        <div className="wow fadeInUp md:hidden block ">
          <img
            src="/images/about/mrcprocess3.png "
            alt="About"
            className="dark:hidden block w-full"
          />
          <img
            src="/images/about/mrcprocess3.png"
            alt="About"
            className="hidden dark:block w-full"
          />
        </div>
      </div>

      <div className="wow fadeInUp ">
        <div className="mx-auto h-auto wow fadeInUp">
          <Swiper
            style={{
              "--swiper-pagination-color": "#8fb1e1",
              "--swiper-navigation-color": "#98cae9",
            }}
            className="opacity-100 pb-20"
            modules={[Pagination, Autoplay]}
            loop={true}
            spaceBetween={50}
            centeredSlides={false}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            autoHeight={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 3,
              },
            }}
            // pagination={{
            //   clickable: true,
            // }}
            // scrollbar={{ draggable: true }}
            // autoHeight="true"
          >
            {mrcDesc.map(({ title, desc }, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className=" border-[1px] border-gray-200  bg-white dark:bg-dark rounded-lg shadow-md p-8 h-40 ">
                    {/* <div className="mb-4 flex justify-between border-b border-stroke dark:border-strokedark pb-6 mb-7.5"> */}
                    <div className="flex justify-between  border-stroke dark:border-strokedark pb-0.5 mb-3.5">
                      <div className="">
                        <h4 className="text-black dark:text-white mb-1.5 font-bold ">
                          # 0{index + 1}
                        </h4>
                        <p className="font-semibold "> {title}</p>
                      </div>
                      {/* <img
                      className=""
                      src="./images/user/user-01.svg"
                      alt="User"
                    /> */}
                    </div>
                    <p className="h-16 dark:text-gray-400">
                      {desc ? " - " + desc : ""}
                    </p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>

      <div className="my-24 border-b-[1px] border-gray-200 "></div>

      {/* desciption - 2 */}
      <div className="wow fadeInUp grid md:grid-cols-2 grid-cols-1 md:mt-28 md:mb-24 mt-16 mb-16 wow fadeInUp">
        <div>
          {/* semi - title */}
          <div className="">
            <h2 className="font-bold md:text-[28px] text-[24px]">MRC 작업</h2>
          </div>
          <div className="h-auto">
            {mrcWork.map(({ title, desc }, index) => {
              return (
                <div key={index} className="mt-6 flex items-center gap-5">
                  <div className="w-[55px] h-[50px] rounded-[50%] border border-stroke bg-[#61a399] dark:border-gray-700 flex items-center justify-center">
                    <p className="text-gray-50 dark:text-white font-semibold   ">
                      0{index + 1}
                    </p>
                  </div>
                  <div className="w-3/4">
                    <p className="text-[16px] font-semibold mb-1.5">{title}</p>
                    <p className="text-[15px] text-gray-600 dark:text-gray-400">
                      {desc}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className=" wow fadeInUp hidden md:block rounded-md p-2 ">
          <img
            src="/images/about/mrcMain.png"
            alt="About"
            className="dark:hidden"
          />
          <img
            src="/images/about/mrcMain.png"
            alt="About"
            className="hidden dark:block"
          />
          <div className="py-7 block">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.fivs.co.kr"
              // className="mr-6 mb-6 inline-flex h-[60px] items-center rounded-lg border-[1.5px] border-[#68afa5] bg-white py-[14px] px-[30px] text-black hover:bg-[#68afa5] hover:text-white dark:bg-white dark:text-black dark:hover:bg-opacity-90"
              className="mr-6 mb-6 inline-flex h-[60px] items-center py-[14px] px-[30px] text-black hover:border-b-[2px] hover:border-b-[#68afa5]  dark:text-white dark:hover:bg-opacity-90"
            >
              MRC(내부통제) 보러가기{" "}
              <IoIosArrowRoundForward className="inline ml-2" size={24} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MrcAuto;
