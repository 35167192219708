import React from "react";
import { IoMdArrowForward } from "react-icons/io";
import { FcDocument } from "react-icons/fc";
import { CgScreen } from "react-icons/cg";
import { FcBarChart } from "react-icons/fc";

//  VALUES 대표 솔루션 - fivs
const WorkProcess = () => {
  return (
    <section id="work-process" className="relative z-10 pt-[110px]">
      <div className="container">
        <div
          className="wow fadeInUp mx-auto mb-14 max-w-[690px] text-center lg:mb-[70px]"
          data-wow-delay=".2s"
        >
          <h2 className="font-bold text-black dark:text-white md:text-[40px] text-[36px] md:leading-tight my-3">
            VALUES 대표 솔루션
          </h2>
          <h4 className="mb-4 text-[20px] font-medium">FIVS</h4>
          <p className="text-base text-body">
            파생상품평가 전문기업으로 매자닌 상품(RCPS, CB, SO 등) 및 IRS, CRS,
            통화선도 등의 파생상품을 평가합니다. 선진화된 평가 기법을 활용하여
            상품의 본질을 반영한 가치를 계산합니다.
          </p>
        </div>
      </div>

      <div className="container max-w-[1390px]">
        <div className="rounded-2xl bg-white px-5 pt-14 pb-14 shadow-card dark:bg-dark dark:shadow-card-dark md:pb-1 lg:pt-20 lg:pb-5 xl:px-10">
          <div className="-mx-4 flex flex-wrap justify-center">
            <div className="w-full px-4 md:w-1/2 lg:w-1/3">
              <div
                className="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                data-wow-delay=".2s"
              >
                <div className="mx-auto mb-2 flex h-[90px] w-[90px] items-center justify-center rounded-3xl bg-gray text-primary duration-300    dark:text-white">
                  <FcDocument size={50} />
                </div>
                <h3 className="mb-4 text-xl font-semibold text-black dark:text-white sm:text-[22px] xl:text-[26px]">
                  FIVS 소개서
                </h3>
                <p className="text-base text-body">
                  파생상품 공정가치 평가에 특화된 플랫폼 FIVS 시스템을
                  공정가치평가 자문 제안서를 통해 소개합니다.
                </p>
              </div>
            </div>

            <div className="w-full px-4 md:w-1/2 lg:w-1/3">
              <div
                className="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                data-wow-delay=".3s"
              >
                <div className="mx-auto mb-2 flex h-[90px] w-[90px] items-center justify-center rounded-3xl bg-gray text-primary duration-300    dark:text-white">
                  <CgScreen size={50} />
                </div>
                <h3 className="mb-4 text-xl font-semibold text-black dark:text-white sm:text-[22px] xl:text-[26px]">
                  FIVS 데모영상
                </h3>
                <p className="text-base text-body">
                  상환전환우선주(RCPS), 전환사채(CB), 교환사채(EB),
                  신주인수권부사채(BW), 스톡옵션(SO) 등의 계산 방법을 보실 수
                  있습니다.
                </p>
              </div>
            </div>

            <div className="w-full px-4 md:w-1/2 lg:w-1/3">
              <div
                className="wow fadeInUp group mx-auto mb-[60px] max-w-[310px] text-center"
                data-wow-delay=".4s"
              >
                <div className="mx-auto mb-2 flex h-[90px] w-[90px] items-center justify-center rounded-3xl bg-gray text-primary duration-300    dark:text-white">
                  <FcBarChart size={50} />
                </div>
                <h3 className="mb-4 text-xl font-semibold text-black dark:text-white sm:text-[22px] xl:text-[26px]">
                  FIVS 보고서 데모
                </h3>
                <p className="text-base text-body">
                  공정가치 평가 결과에 대한 산출 보고서를 FIVS를 통해 샘플 파일
                  형태로 보실 수 있습니다. <br />
                  (PPTX 파일 기준)
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.fivs.co.kr"
              className=""
            >
              <div className="flex items-center md:text-[17px] text-[16px] font-medium  dark:text-white">
                <span className="mr-[5px]">FIVS로 이동</span>
                <IoMdArrowForward />
              </div>
            </a>
          </div>
        </div>
      </div>

      {/* <!-- Graphics --> */}
      <div className="absolute -top-28 left-0 -z-10 hidden md:block">
        <svg
          width="632"
          height="1179"
          viewBox="0 0 632 1179"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.25" filter="url(#filter0_f_38_24)">
            <circle
              cx="42.5"
              cy="589.5"
              r="329.5"
              fill="url(#paint0_linear_38_24)"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_38_24"
              x="-547"
              y="0"
              width="1179"
              height="1179"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="130"
                result="effect1_foregroundBlur_38_24"
              />
            </filter>
            <linearGradient
              id="paint0_linear_38_24"
              x1="-366.218"
              y1="919"
              x2="451.176"
              y2="349.901"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8EA5FE" />
              <stop offset="0.541667" stop-color="#BEB3FD" />
              <stop offset="1" stop-color="#90D1FF" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="absolute right-0 top-20 -z-10">
        <svg
          width="637"
          height="1277"
          viewBox="0 0 637 1277"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.2" filter="url(#filter0_f_38_23)">
            <circle
              cx="638.5"
              cy="638.5"
              r="388.5"
              fill="url(#paint0_linear_38_23)"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_38_23"
              x="0"
              y="0"
              width="1277"
              height="1277"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="125"
                result="effect1_foregroundBlur_38_23"
              />
            </filter>
            <linearGradient
              id="paint0_linear_38_23"
              x1="250"
              y1="250"
              x2="1168.59"
              y2="782.957"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FF8FE8" />
              <stop offset="1" stop-color="#FFC960" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
  );
};

export default WorkProcess;
