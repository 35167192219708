import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// "준비중입니다." - 공통 문구 페이지
const PreparingPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤
  }, [pathname]);

  return (
    <section className="pt-2 md:pt-4 pb-24 md:pb-32 overflow-hidden">
      <div className=" mx-auto md:w-5/12 w-11/12 text-center">
        <img
          src="./images/icon/engineer2.png"
          alt="준비중"
          className="mx-auto mb-7.5 md:w-2/5 w-3/5 mb-3"
        />
        <div className="flex justify-center items-center">
          <h2 className="font-semibold text-2xl md:text-4xl text-black dark:text-white mb-5">
            현재 준비중인 기능입니다.
          </h2>
        </div>

        <p className="mb-7">
          빠른 시일내에 해당 기능을 만나보실 수 있습니다. 감사합니다.
        </p>

        <Link
          to="/"
          className="inline-flex items-center gap-2.5 bg-black dark:bg-btndark hover:bg-blackho ease-in-out duration-300 font-medium text-white rounded-full px-6 py-3"
        >
          메인페이지로 이동
        </Link>
      </div>
    </section>
  );
};

export default PreparingPage;
