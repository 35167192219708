import React, { useEffect, useRef, useState } from "react";

const RevealOnScroll = ({ item }) => {
  const ref = useRef(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // entries : 현재 감시 중인 모든 요소가 출력됨
        entries.forEach(({ target, isIntersecting }) => {
          if (target === ref.current) {
            // visible을 isIntersecting(boolean)의 값으로 바꿔줘라
            // isIntersecting : target과 root가 교차된 상태인지(true) 아닌지(false)를 boolean값으로 반환한다.
            setVisible(isIntersecting);
            console.log(visible);
          }
        });
      },
      {
        threshold: 0.9,
      }
    );
    // ref.current가 참이면(visible이 true)
    if (ref.current) {
      // 해당 타겟 ref를 Observer가 관찰할 수 있도록 넣어준다
      // .observe() : 타겟요소가 화면에 보이는지 관찰하는 역할
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      className={`mt-10 transition-all duration-500 ${
        visible ? "opacity-100" : "opacity-40"
      }`}
      key={item.id}
      ref={ref}
    >
      <img
        src={`${process.env.PUBLIC_URL}/images/hero/sales${item.id}.png`}
        alt="logo"
      />
    </div>
  );
};

export default RevealOnScroll;
