import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { FiMessageCircle } from "react-icons/fi";
import WOW from "wowjs";
import Partners from "../../LandingPage/Component/Partners";
import { Link } from "react-router-dom";

// 회사소개 - VALUES 파트너
const CompanyPartner = () => {
  useEffect(() => {
    window.wow = new WOW.WOW({
      live: false,
    });

    window.wow.init({
      offset: 50,
    });
  }, []);

  //partners List START
  const partners = [
    { src: "/images/logo/wooribank.png", alt: "wooribank" },
    { src: "/images/logo/samil.png", alt: "samil" },
    { src: "/images/logo/anse.png", alt: "anse" },
    { src: "/images/logo/removebg-chang.png", alt: "chang" },
    { src: "/images/logo/beyul.png", alt: "beyul" },
    { src: "/images/logo/solbon.png", alt: "solbon" },
    { src: "/images/logo/lodin.png", alt: "lodin" },
    { src: "/images/logo/juro.png", alt: "juro_logo" },
    { src: "/images/logo/donga.png", alt: "donga" },
    { src: "/images/logo/woori_logo.png", alt: "woori_logo" },
    { src: "/images/logo/han-removebg.png", alt: "hansu" },
    { src: "/images/logo/bomyung.png", alt: "bomyung" },
  ];

  return (
    <div className="">
      {/* title */}
      <div className="mb-2 border-b-[2px] border-stroke wow fadeInUp">
        <h2 className="font-bold md:text-[30px] text-[26px] mb-2">
          VALUES 파트너
        </h2>
      </div>
      <div className="my-6 md:mb-10 mb-6 wow fadeInUp">
        <div className="font-semibold md:text-[17px] text-[16px] my-1.5">
          함께 성장하는 파트너, 더 큰 가치를 창출합니다
        </div>
        <div className="md:text-[16px] text-[15px] dark:text-gray-400 wow fadeInUp">
          다양한 분야에서 신뢰할 수 있는 파트너들과 함께 협력하여 최고의 가치를
          제공하고, 고객에게 최상의 솔루션과 서비스를 전달합니다.
          <br />
        </div>
      </div>

      {/* semi - title */}
      {/* <div className="wow fadeInUp">
        <h2 className="font-bold md:text-[28px] text-[24px]">
          VALUES 파트너사 가치
        </h2>
      </div> */}

      {/* desciption */}
      <div className="mb-10 wow fadeInUp w-11/12 px-10">
        <div className="hidden md:block wow fadeInUp">
          <img
            src="/images/about/valuesPatner.png"
            alt="valuesPatner"
            className="dark:hidden"
          />
          <img
            src="/images/about/valuesPatner2.png"
            alt="valuesPatner"
            className="hidden dark:block"
          />
        </div>
      </div>
      {/* desciption -모바일용 */}
      <div className="mb-6 wow fadeInUp w-full">
        <div className="block md:hidden wow fadeInUp">
          <img
            src="/images/about/valuesPatner3.png"
            alt="valuesPatner"
            className="dark:hidden"
          />
          <img
            src="/images/about/valuesPatner4.png"
            alt="valuesPatner"
            className="hidden dark:block"
          />
        </div>
      </div>

      {/* desciption - 2 */}
      <div className=" items-center wow fadeInUp mb-10">
        <div className="font-semibold md:text-[19px] text-[17px] py-4 my-4 border-b-[2px] border-stroke">
          신뢰와 협력을 바탕으로 함께하는 VALUES의 파트너사를 소개합니다.
        </div>
        <div className=" md:text-[17px] text-[15px]">
          VALUES는 다양한 전문성을 갖춘 파트너들과의 협업을 통해 더 큰 가치를
          창출하고 있습니다. <br />
          함께 미래를 만들어갈 파트너를 언제나 환영합니다.
        </div>
        <div className="md:block hidden">
          <div className="flex justify-start mt-5 gap-2 ">
            <Link
              to="/rcpsSupport"
              className="bg-[#61a399] py-3 px-5 text-white rounded-md md:text-[16px] text-[15px] "
            >
              파생상품 관련문의 바로가기
            </Link>
            <Link
              to="/webSupport"
              className="bg-[#61a399] py-3 px-5 text-white rounded-md md:text-[16px] text-[15px] "
            >
              웹 개발 또는 유지보수 문의 바로가기
            </Link>
          </div>
        </div>
        <div className="md:hidden block">
          <div className="flex justify-start mt-5 gap-2">
            <Link
              to="/rcpsSupport"
              className="bg-[#61a399] py-3 px-5 text-white rounded-md md:text-[16px] text-[13px] "
            >
              파생상품 관련문의
            </Link>
            <Link
              to="/webSupport"
              className="bg-[#61a399] py-3 px-5 text-white rounded-md md:text-[16px] text-[13px] "
            >
              웹 개발 또는 유지보수 문의
            </Link>
          </div>
        </div>
      </div>

      {/* values partners */}
      <section className="md:my-12 my-12 ">
        <div className="mx-auto max-w-c-1390 md:px-2 px-2 dark:bg-stroke border border-x-0 border-y-stroke dark:border-y-strokedark py-4">
          <Swiper
            className="partners-swiper grid grid-cols-3 md:grid-cols-6 gap-7.5 lg:gap-12.5 xl:gap-29 justify-center items-center "
            // style={{
            //   "--swiper-pagination-color": "#8fb1e1",
            //   "--swiper-navigation-color": "#98cae9",
            // }}
            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
            spaceBetween={50}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              640: {
                slidesPerView: 5,
                spaceBetween: 40,
              },
              1200: {
                slidesPerView: 6,
                spaceBetween: 40,
              },
            }}
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            // autoHeight="true"
          >
            <div className="">
              {partners.map(({ src, alt }, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className="md:h-12  partners-swiper-slide"
                  >
                    <div className="flex !items-center justify-center">
                      <img
                        className="object-cover 2xl:w-8/12 md:w-9/12 w-10/12"
                        src={src}
                        alt={alt}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>
        </div>
      </section>
    </div>
  );
};

export default CompanyPartner;
