import React, { useEffect, useRef } from "react";
import "./landing.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Features from "./Component/Features";
import About from "./Component/About";
import WorkProcess from "./Component/WorkProcess";
import AppScreen from "./Component/AppScreen";
import Cta from "./Component/Cta";
import Testimonials from "./Component/Testimonials";
import Faq from "./Component/Faq";
import SendMessage from "./Component/SendMessage";
import WOW from "wowjs";
import News from "./Component/News";
import Partners from "./Component/Partners";
import { Link, useLocation } from "react-router-dom";

// 메인 페이지
const LandingPage = () => {
  const { pathname } = useLocation();
  //지연 슬라이드 START
  // const leftInputRef = useRef();
  // const rightInputRef = useRef();
  useEffect(() => {
    window.wow = new WOW.WOW({
      live: false,
    });

    window.wow.init({
      offset: 50,
    });
  }, []);
  //지연 슬라이드 END

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤
  }, [pathname]);

  return (
    <>
      {/* LANDING MAIN IMAGE Start */}
      <section className="pt-[65px] overflow-hidden md:my-16">
        <div className="mt-10 md:h-[500px] h-[800px] w-full flex justify-center">
          <div className="md:max-w-[1340px] w-full grid md:grid-cols-2 grid-cols-1 2xl:px-1 md:px-6 px-6">
            {/* 메인 상단 좌측 패널 grid 1/2 */}
            <div className="wow fadeInUp pl-2 pt-10" data-wow-delay=".2s">
              <span className="mb-5 block text-lg font-medium leading-tight text-black dark:text-white sm:text-[22px] xl:text-[22px]">
                효율적인 시스템 구축부터 안정적인 운영까지
              </span>

              <h1 className="mb-6 text-3xl font-bold leading-tight text-black dark:text-white sm:text-[40px] md:text-[50px] lg:text-[42px] xl:text-[50px]">
                IT 개발 구축 및 솔루션 기업
                <span className="inline bg-gradient-1 bg-clip-text text-transparent">
                  {" "}
                  VALUES{" "}
                </span>
              </h1>
              <p className="mb-10 max-w-[475px] text-base leading-relaxed text-body">
                파생상품 자동화 시스템 FIVS 구축, 웹 솔루션과 유지보수 등 기업의
                성장을 이끄는 파트너가 되어 드립니다. 미래를 위한 IT 인프라를
                완성하세요.
              </p>
              <div className="flex flex-wrap items-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.fivs.co.kr"
                  className="mr-6 mb-6 inline-flex h-[60px] items-center rounded-lg bg-[#68afa5] py-[14px] px-[30px] text-white hover:bg-opacity-100 dark:bg-white dark:text-black dark:hover:bg-opacity-90"
                >
                  <span className="mr-[18px] border-r border-stroke border-opacity-40 pr-[18px] leading-relaxed dark:border-[#BDBDBD]">
                    FIVS로 이동
                  </span>
                  <span>
                    <svg
                      width="14"
                      height="16"
                      viewBox="0 0 14 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 7.06367C14.1667 7.44857 14.1667 8.41082 13.5 8.79572L1.5 15.7239C0.833334 16.1088 -3.3649e-08 15.6277 0 14.8579L6.05683e-07 1.00149C6.39332e-07 0.231693 0.833334 -0.249434 1.5 0.135466L13.5 7.06367Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </a>

                <Link
                  // href="javascript:void(0)"
                  to="webCareer"
                  className="glightbox mb-6 inline-flex items-center py-4 text-black hover:text-primary dark:text-white dark:hover:text-primary"
                >
                  <span className="mr-[22px] flex h-[60px] w-[60px] items-center justify-center rounded-full border-2 border-current">
                    <svg
                      width="14"
                      height="16"
                      viewBox="0 0 14 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 7.06367C14.1667 7.44857 14.1667 8.41082 13.5 8.79572L1.5 15.7239C0.833334 16.1088 -3.3649e-08 15.6277 0 14.8579L6.05683e-07 1.00149C6.39332e-07 0.231693 0.833334 -0.249434 1.5 0.135466L13.5 7.06367Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span className="text-base font-medium">
                    <span className="block text-sm">VALUES </span>
                    개발 이력 보기
                  </span>
                </Link>
              </div>
            </div>

            {/* 메인 상단 우측 패널 grid 1/2 */}
            <div className="bg-gray dark:bg-black overflow-hidden">
              <div
                className="wow fadeInUp flex flex-row z-10 "
                data-wow-delay=".3s"
              >
                <div className="slideDiv">
                  <div className="slideDown">
                    <img
                      src="/images/hero/left-top.png"
                      alt="hero image"
                      className="mx-auto max-w-none"
                    />
                  </div>
                  <div className="slideDown">
                    <img
                      src="/images/hero/left-bottom.png"
                      alt="hero image"
                      className="mx-auto max-w-none"
                    />
                  </div>
                </div>

                <div className="slideDiv">
                  <div className="slideUp">
                    <img
                      src="/images/hero/right-top.png"
                      alt="hero image"
                      className="mx-auto max-w-none"
                    />
                  </div>
                  <div className="slideUp">
                    <img
                      src="/images/hero/right-bottom.png"
                      alt="hero image"
                      className="mx-auto max-w-none"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* grid end */}
          </div>
        </div>
      </section>
      {/* LANDING MAIN IMAGE End */}

      {/* values의 기능 소개 */}
      <Features />

      {/*  values가 하는일들 - IT 개발 구축 */}
      <Cta />

      {/*  values의 대표 솔루션 - FIVS */}
      <WorkProcess />

      {/* 이 컴포넌트는 개발이력에서 쓰일 예정 */}
      {/* <AppScreen /> */}

      {/* 클라이언트 후기 - 위시켓꺼 참고할 예정 */}
      <Testimonials />

      {/* Faq -자주 묻는 질문 */}
      <Faq />

      {/* New -공지사항 */}
      <News />

      {/* VALUES Partners */}
      <Partners />

      {/* SendMessage -문의 - select option 추가 : 파생상품관련문의, 웹 개발 문의, 웹 유지보수 문의 */}
      <SendMessage />
    </>
  );
};

export default LandingPage;
