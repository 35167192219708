import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { FiMessageCircle } from "react-icons/fi";
import WOW from "wowjs";

// IT 개발 솔루션 - FIVS
const FivsSolution = () => {
  useEffect(() => {
    window.wow = new WOW.WOW({
      live: false,
    });

    window.wow.init({
      offset: 50,
    });
  }, []);

  //FIVS 6 TASK List
  const fivsSixTaskList = [
    {
      title: "정확한 공정가치평가",
      desc: "파생상품평가에 적합한 금융공학 모델링을 적용하며, 대형 증권사 수준으로 구현하였습니다.",
    },
    {
      title: "신속한 계산",
      desc: "편리한 인터페이스 및 자동화된 시스템으로 빠른 시간 내에 산출이 가능합니다.",
    },
    {
      title: "투명한 과정을 제공",
      desc: "평가결과 엑셀과 보고서를 제공합니다",
    },
    {
      title: "고객맞춤 서비스",
      desc: "고객사에서 요구하는 데이터 및 보고서를 원하는 형태로 제공이 가능합니다",
    },
    {
      title: "시나리오 테스트",
      desc: "KOSPI 또는 KOSDAQ 수익을 기반 또는 사용자 지정의 시나리오테스트로 손익예측에 도움을 줄 수 있습니다",
    },
    {
      title: "고객 응대",
      desc: "솔루션 사용에 질문 및 문제가 생길 시 재빠른 응대로 에러사항을 최소화 하도록 하겠습니다",
    },
  ];

  const demoDesc = [
    {
      title: "정확한 공정가치평가",
      desc: "공정가치 평가 프로세스를 효욜적으로 진행 가능하도록 하였습니다.",
    },

    {
      title: "히스토리 데이터 관리",
      desc: "히스토리 데이터 관리를 통하여 추후 평가정보 확인 및 리스트 관리를 용이하게 사용 가능하도록 하였습니다.",
    },
    {
      title: "보고서작성 자동화",
      desc: "에러를 최소화하여 평가 프로세스를 사용함에 있어 불편함이 없도록 하였습니다. Basic Base 보고서작성을 자동화하였습니다.",
    },
    {
      title: "Financial Engineering",
      desc: "파생상품평가에 적합한 금융공학 모델링을 적용하며 대형 증권사 수준으로 구현",
    },
  ];

  return (
    <div className="">
      {/* title */}
      <div className="mb-2 border-b-[2px] border-stroke wow fadeInUp">
        <h2 className="font-bold md:text-[30px] text-[26px] mb-2">
          VALUES의 대표 솔루션 - FIVS
        </h2>
      </div>
      <div className="my-6 mb-20 wow fadeInUp md:text-[16px] text-[15px] dark:text-gray-400">
        <div className="my-1.5">
          파생상품평가 전문 FIVS는 매자닌 상품(CB, RCPS, SO 등) 및 IRS, CRS,
          통화선도 등의 파생상품을 평가합니다.
        </div>

        <div className="my-1.5">
          선진화된 평가 기법을 활용하여 상품의 본질을 반영한 가치를 계산합니다.{" "}
        </div>
        <div className="my-1.5">
          평가부터 보고서까지 one-stop으로 사용자가 손쉽게 평가할 수 있는 툴을
          제공합니다.
        </div>
        <div className="my-1.5">
          평가의 파라미터부터 결과까지 투명한 평가를 지향합니다.
        </div>
      </div>

      {/* semi - title */}
      <div className="wow fadeInUp">
        <h2 className="font-bold md:text-[28px] text-[24px]">FIVS WORK FLOW</h2>
      </div>

      {/* desciption */}
      <div className="grid md:grid-cols-2 grid-cols-1 mb-24 wow fadeInUp">
        <div className="h-auto">
          {fivsSixTaskList.map(({ title, desc }, index) => {
            return (
              <div key={index} className="mt-6 flex items-center gap-5">
                <div className="w-[55px] h-[50px] rounded-[50%] border border-stroke bg-[#61a399] dark:border-gray-700 flex items-center justify-center">
                  <p className="text-gray-50 dark:text-white font-semibold   ">
                    0{index + 1}
                  </p>
                </div>
                <div className="w-3/4">
                  <p className="text-[16px] font-semibold mb-1.5">{title}</p>
                  <p className="text-[15px] text-gray-600 dark:text-gray-400">
                    {desc}
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="hidden md:block wow fadeInUp">
          <img
            src="/images/about/process.png"
            alt="About"
            className="dark:hidden"
          />
          <img
            src="/images/about/process2.png"
            alt="About"
            className="hidden dark:block"
          />
        </div>
      </div>

      {/* desciption - 2 */}
      <div className="flex gap-2 items-center wow fadeInUp">
        <FiMessageCircle size={32} color="green" />
        <div className="font-semibold md:text-[19px] text-[18px]">
          FIVS는 정교한 평가기법과 투입변수 산출 및 보고서 자동화를 통해 사용이
          용이합니다.
        </div>
      </div>
      <div className="my-8">
        <div className="mx-auto h-auto wow fadeInUp">
          <Swiper
            style={{
              "--swiper-pagination-color": "#8fb1e1",
              "--swiper-navigation-color": "#98cae9",
            }}
            className="opacity-100 pb-20"
            modules={[Pagination, Autoplay]}
            loop={true}
            spaceBetween={50}
            centeredSlides={false}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            autoHeight={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
            }}
            // pagination={{
            //   clickable: true,
            // }}
            // scrollbar={{ draggable: true }}
            // autoHeight="true"
          >
            {demoDesc.map(({ title, desc }, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className=" border-[1px] border-gray-200  bg-white dark:bg-dark rounded-lg shadow-md p-8 h-52 ">
                    <div className="mb-4 flex justify-between border-b border-stroke dark:border-strokedark pb-6 mb-7.5">
                      <div className="">
                        <h4 className="text-black dark:text-white mb-1.5 font-bold">
                          # 0{index + 1}
                        </h4>
                        <p className="font-semibold "> {title}</p>
                      </div>
                      {/* <img
                      className=""
                      src="./images/user/user-01.svg"
                      alt="User"
                    /> */}
                    </div>
                    <p className="h-16 dark:text-gray-400">{desc}</p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>

      <div
        className="my-24 border-b-[1px] border-gray-200
       "
      ></div>

      <div className="grid md:grid-cols-2 grid-cols-1 md:mt-24 md:mb-24 mt-16 mb-16 wow fadeInUp">
        <div>
          <div className="mb-5">
            <h2 className="font-bold md:text-[28px] text-[24px]">PRICING</h2>
          </div>
          <div className="">
            <label className="font-medium md:text-[17px] text-[16px]">
              FIVS는 매자닌 상품(RCPS,CB, SO 등) 및 IRS, CRS, 통화선도 등의
              파생상품을 평가합니다. <br />
              선진화된 평가 기법을 활용하여 상품의 본질을 반영한 가치를
              계산합니다.
            </label>{" "}
            <br />
          </div>
          <div className="pt-4">
            <label className="font-medium">
              FIVS 데모영상은 아래 링크를 클릭하시면 보실 수 있습니다.
            </label>
          </div>
          <div className="py-7">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.fivs.co.kr"
              className="mr-6 mb-6 inline-flex h-[60px] items-center rounded-lg bg-[#68afa5] py-[14px] px-[30px] text-white hover:bg-opacity-100 dark:bg-white dark:text-black dark:hover:bg-opacity-90"
            >
              FIVS 데모영상 보러가기
            </a>
          </div>
        </div>
        <div className="hidden md:block rounded-md p-2 dark:border-[0px]  border-stroke ">
          <img
            src="/images/about/fivs-main.png"
            alt="About"
            className="dark:hidden"
          />
          <img
            src="/images/about/fivs-main.png"
            alt="About"
            className="hidden dark:block"
          />
        </div>
      </div>
    </div>
  );
};

export default FivsSolution;
