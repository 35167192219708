import React, { useEffect, useRef, useState } from "react";
import WOW from "wowjs";
import { IoBarChart } from "react-icons/io5";
import { useLocation } from "react-router-dom";

// 파생상품 평가 이력
const RcpsCareer = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 상단으로 스크롤
  }, [pathname]);

  useEffect(() => {
    window.wow = new WOW.WOW({
      live: false,
    });

    window.wow.init({
      offset: 50,
    });
  }, []);

  // 연도
  const texts = ["2024", "2023", "2022"];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  // 감지할 div 요소들을 참조
  const sectionRefs = useRef([]);

  // 2024 이력
  const menuText2024 = [
    { date: "10", desc: "우리은행 파트너 등록" },
    { date: "09", desc: "삼일회계법인 파트너 등록" },
    { date: "08", desc: "안세회계법인 파트너 등록" },
    { date: "05", desc: "창의회계법인 파트너 등록" },
    { date: "03", desc: "회계법인 배율 파트너 등록" },
  ];
  // 2023 이력
  const menuText2023 = [
    { date: "10", desc: "회계법인 솔본 파트너 등록" },
    { date: "08", desc: "LORDIN 파트너 등록" },
    { date: "08", desc: "유로인스트루먼츠 파트너 등록" },
    { date: "07", desc: "동아송강회계법인 파트너 등록" },
    { date: "03", desc: "우리회계법인 파트너 등록" },
  ];
  // 2022 이력
  const menuText2022 = [
    { date: "09", desc: "한국수출입은행 파트너 등록" },
    { date: "05", desc: "보명회계법인 파트너 등록" },
  ];

  useEffect(() => {
    // Intersection Observer 설정
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // 특정 div가 화면에 보일 때 해당 인덱스에 맞는 텍스트로 변경
            const index = sectionRefs.current.indexOf(entry.target);
            // console.log(entry.target);
            // console.log(index);
            setCurrentTextIndex(index);
          }
        });
      },
      { threshold: 0.5 } // 요소가 50% 이상 보일 때 트리거
    );

    // 모든 sectionRefs 요소에 observer 연결
    sectionRefs.current.forEach((section) => observer.observe(section));

    // 컴포넌트가 언마운트될 때 observer 해제
    // return () => {
    //   sectionRefs.current.forEach((section) => observer.unobserve(section));
    // };
  }, []);

  // *** 스크롤 위치가 맨 밑에 도달하면 이미지를 숨기기 위한 코드 START ***
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = () => {
    // 스크롤 위치가 하단에 있는지 확인
    const scrollPosition = window.scrollY + window.innerHeight;
    const pageHeight = document.documentElement.scrollHeight;

    if (pageHeight - scrollPosition <= 120) {
      setIsBottom(true); // 하단에서 120px 위정도 도달하면 true로 설정
    } else {
      setIsBottom(false); // 하단이 아니면 false로 설정
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // *** 스크롤 위치가 맨 밑에 도달하면 이미지를 숨기기 위한 코드 END ***
  return (
    <div>
      {/* title */}
      {/* <h2 className="wow fadeInUp md:text-[15px] text-[14px] font-medium text-left mb-8 py-4 ">
        VALUES의 웹 개발 및 유지보수 이력을 보실 수 있습니다.
      </h2> */}

      <div className="grid md:grid-cols-2 grid-cols-1 ">
        {/* 연도 */}
        <div className="h-auto text-black md:block hidden">
          <div className="md:fixed md:top-16 p-40 w-1/2">
            {!isBottom && (
              <div className="flex-col">
                <label className="font-bold md:text-[130px] text-[#4b9488]">
                  {texts[currentTextIndex]}
                </label>
                <div className="flex justify-center wow fadeInLeft">
                  <img
                    src="/images/about/valuesCareer.png"
                    alt="valuesCareer"
                    className="w-[270px] inline px-1 dark:hidden"
                  />
                  <img
                    src="/images/about/valuesCareer2.png"
                    alt="valuesCareer2"
                    className="w-[270px]  px-1 dark:inline hidden"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* 모바일에서 보이는 이미지 */}
        <div className="wow fadeInUp md:hidden flex justify-center">
          <img
            src="/images/about/valuesCareer.png"
            alt="valuesCareer"
            className="w-4/5 inline px-1 my-2"
          />
        </div>

        <div className="wow fadeInUp ">
          {/* 2024 */}
          <div id="1" ref={(el) => (sectionRefs.current[0] = el)}>
            <div className="relative border-l border-gray-200 dark:border-gray-700">
              <div className="md:mb-36 mb-20 ml-14">
                {/* 모바일에서만 보이는 영역 */}
                <div className=" md:hidden block">
                  <div className="flex items-center">
                    <span className="flex absolute -left-2 justify-center items-center w-4 h-4 bg-[#57948b] rounded-full ring-8 ring-white dark:ring-gray-800 dark:bg-[#57948b]"></span>
                    <h3 className="md:flex items-center md:text-[32px] text-[28px] font-semibold text-gray-900 dark:text-white">
                      {texts[currentTextIndex]}
                    </h3>
                  </div>
                </div>
                {menuText2024.map(({ date, desc }) => {
                  return (
                    <div className="py-4 mb-2">
                      <label className="mb-1 md:text-[20px] text-[19px] font-medium text-black dark:text-gray-400">
                        {date}
                      </label>
                      <div className="my-1.5"></div>
                      <label className="md:text-[16.5px] text-[15px] text-gray-800 dark:text-gray-400 ">
                        {desc}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* 2023 */}
          <div id="2" ref={(el) => (sectionRefs.current[1] = el)}>
            <div className="relative border-l border-gray-200 dark:border-gray-700">
              <div className="md:mb-36 mb-20 ml-14">
                {/* 모바일에서만 보이는 영역 */}
                <div className=" md:hidden block">
                  <div className="flex items-center">
                    <span className="flex absolute -left-2 justify-center items-center w-4 h-4 bg-[#57948b] rounded-full ring-8 ring-white dark:ring-gray-800 dark:bg-[#57948b]"></span>
                    <h3 className="md:flex items-center md:text-[32px] text-[28px] font-semibold text-gray-900 dark:text-white">
                      {texts[currentTextIndex]}
                    </h3>
                  </div>
                </div>
                {menuText2023.map(({ date, desc }) => {
                  return (
                    <div className="py-4 mb-2">
                      <label className="mb-1 md:text-[18px] text-[17px] font-semibold text-black dark:text-gray-400">
                        {date}
                      </label>
                      <div className="my-1.5"></div>
                      <label className="md:text-[16.5px] text-[15px] text-gray-800 dark:text-gray-400 ">
                        {desc}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* 2022 */}
          <div id="3" ref={(el) => (sectionRefs.current[2] = el)}>
            <div className="relative border-l border-gray-200 dark:border-gray-700">
              <div className="md:mb-36 mb-20 ml-14">
                {/* 모바일에서만 보이는 영역 */}
                <div className=" md:hidden block">
                  <div className="flex items-center">
                    <span className="flex absolute -left-2 justify-center items-center w-4 h-4 bg-[#57948b] rounded-full ring-8 ring-white dark:ring-gray-800 dark:bg-[#57948b]"></span>
                    <h3 className="md:flex items-center md:text-[32px] text-[28px] font-semibold text-gray-900 dark:text-white">
                      {texts[currentTextIndex]}
                    </h3>
                  </div>
                </div>
                {menuText2022.map(({ date, desc }) => {
                  return (
                    <div className="py-4 mb-2">
                      <label className="mb-1 md:text-[18px] text-[17px] font-semibold text-black dark:text-gray-400">
                        {date}
                      </label>
                      <div className="my-1.5"></div>
                      <label className="md:text-[16.5px] text-[15px] text-gray-800 dark:text-gray-400 ">
                        {desc}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RcpsCareer;
